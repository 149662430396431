import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddClientForm = () => {
  
  const [clientMasters, setClientMasters] = useState([]);
  const [clientMasterId, setClientMasterId] = useState("");
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gstno, setGstno] = useState("");
  const [phone, setPhone] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [clientNames, setClientNames] = useState([]);
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientMastersResponse, supervisorsResponse] = await Promise.all([
          axios.get('https://jubilant-backend.netlysystems.com/clientNames'),
          axios.get('https://jubilant-backend.netlysystems.com/supervisors')
        ]);

        setClientMasters(clientMastersResponse.data);
        setSupervisorsList(supervisorsResponse.data);
      } catch (err) {
        setError('Error fetching data');
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handleClientMasterChange = (e) => {
    const selectedClientMasterId = e.target.value;
    setClientMasterId(selectedClientMasterId);

    // Find the name of the selected client master
    const selectedClientMaster = clientMasters.find(client => client._id === selectedClientMasterId);
    if (selectedClientMaster) {
      setFname(selectedClientMaster.fname);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://jubilant-backend.netlysystems.com/addClient', {
      clientMasterId,
      fname,
      email,
      address,
      gstno,
      phone,
      supervisor
    })
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          alert("Client Successfully Added");
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Site</h1>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Site Name</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={handleClientMasterChange} required
        >
          <option value="">Select Site</option>
          {clientMasters.map((client) => (
            <option key={client._id} value={client._id}>
              {client.fname}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter Email"
          style={{ width: '100%' }}
          onChange={(e) => setEmail(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Address</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Address"
          style={{ width: '100%' }}
          onChange={(e) => setAddress(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Gst.No</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Gst.No"
          style={{ width: '100%' }}
          onChange={(e) => setGstno(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Contact</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Phone Number"
          style={{ width: '100%' }}
          onChange={(e) => setPhone(e.target.value)} required
        />
      </div>
      
      <div style={{ width: '100%' }}>
        <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
          Add Client
        </button>
      </div>
    </form>
  );
}

export default AddClientForm;