import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';

const AdminTransferMachineDashboard = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [machineTypes, setMachineTypes] = useState([]);
    const [machineCategory, setMachineCategory] = useState([]);
    const [clients, setClients] = useState([]);

    const usersPerPage = 30; // Show only 30 users per page

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchMachineTypes = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/machineTypes")
            .then((res) => res.json())
            .then((data) => {
                setMachineTypes(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchMachineCategory = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/machineCategory")
            .then((res) => res.json())
            .then((data) => {
                setMachineCategory(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const fetchData = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllTransferMachine", {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        fetchData();
        fetchMachineTypes();
        fetchMachineCategory();
    }, [fetchData, fetchMachineTypes, fetchMachineCategory]);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    if (!isLoggedIn) {
        alert("Token Expired. Please login to see the content.");
        window.location.href = "/";
        return null;
    }

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    let filteredData = data;
    if (searchTerm.trim() !== '') {
        filteredData = data.filter(user => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                user.machineType?.toLowerCase().includes(term) ||
                user.challanNo?.toLowerCase().includes(term) ||
                user.machineCategory?.toLowerCase().includes(term) ||
                user.transporterName?.toLowerCase().includes(term) ||
                user.type?.toLowerCase().includes(term) ||
                user.sno?.toLowerCase().includes(term) ||
                user.category?.toLowerCase().includes(term) ||
                user.oldClient?.toLowerCase().includes(term) ||
                user.newClientName?.toLowerCase().includes(term) ||
                user.ewaybill?.toLowerCase().includes(term) ||
                user.battery?.toLowerCase().includes(term) ||
                user.oldState?.toLowerCase().includes(term) ||
                user.state?.toLowerCase().includes(term) ||
                (user.weight && String(user.weight).toLowerCase().includes(term))
            );
        });
    }

    if (searchDate.trim() !== '') {
        filteredData = filteredData.filter(user => {
            const inputDate = new Date(searchDate).setHours(0, 0, 0, 0);
            const userDate = new Date(user.date).setHours(0, 0, 0, 0);
            return inputDate === userDate;
        });
    }
    filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    const totalPages = Math.ceil(filteredData.length / usersPerPage);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i> Requests</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to='/adminTransferMachine' className="active"><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminTransferMachine">Transfer Machine</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machines :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Machine type, challan.no, category, old site, new site, transporter name, e-way bill or concern person..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <br />
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0", width: "65%", margin: "auto", borderRadius: "5px", }} className="search-bar-container">
                            <h3 style={{ color: "white", margin: "2px 10px 2px 0", fontSize: "20px", fontWeight: "bolder" }}>Search by Date:</h3>
                            <input
                                type="date"
                                placeholder="Search by Date"
                                value={searchDate}
                                onChange={(e) => setSearchDate(e.target.value)}
                                style={{
                                    outline: "none",
                                    border: "none",
                                    background: "white",
                                    color: "black",
                                    fontSize: "14px",
                                    padding: "8px",
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        {currentUsers.length === 0 && <p>Machine doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "125px", backgroundColor: "#007bff", color: "white" }}>Date</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Challan.No</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>E-Way Bill</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Machine S.No</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Machine Category</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Type</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Battery Type</th>
                                    <th style={{ width: "220px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity <span style={{ color: "black", fontWeight: "bolder" }}>(TON)*</span></th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Dispatched From</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Dispatched To</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Old State</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>New State</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Transporter Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "125px", border: "1px solid black" }}>{formatDate(user.date)}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.challanNo}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.ewaybill}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.sno}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.type}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.category}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.battery}</td>
                                        <td style={{ width: "220px", border: "1px solid black" }}>{user.weight}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.oldClient}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.newClientName}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.oldState}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.transporterName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
}

export default AdminTransferMachineDashboard;