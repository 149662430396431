import React, { useEffect, useState } from "react";
import "./css/DashboardHome.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function MachineMaster() {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(true);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [brand, setBrand] = useState("");
    const [weight, setWeight] = useState("");
    const [machineTypes, setMachineTypes] = useState([]);
    const [machineCategory, setMachineCategory] = useState([]);
    const [machineBrand, setMachineBrand] = useState([]);
    const [machineWeight, setMachineWeight] = useState([]);
    const [editModeType, setEditModeType] = useState(false);
    const [editingType, setEditingType] = useState({});
    const [editModeCategory, setEditModeCategory] = useState(false);
    const [editingCategory, setEditingCategory] = useState({});
    const [editModeBrand, setEditModeBrand] = useState(false);
    const [editingBrand, setEditingBrand] = useState({});
    const [editModeWeight, setEditModeWeight] = useState(false);
    const [editingWeight, setEditingWeight] = useState({});

    useEffect(() => {
        fetchMachineTypes();
        fetchMachineCategories();
        fetchMachineBrands();
        fetchMachineWeights();
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const handleUpdateType = async (id, newType) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateTypeMaster/${id}`, { type: newType });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("Type Master and related Types Successfully Updated");
                fetchMachineTypes();
                setEditModeType(false);
                setEditingType({});
            }
        } catch (error) {
            console.error('Error updating Type:', error);
            alert('Failed to update Type');
        }
    };
    const handleTypeEditClick = (type) => {
        setEditModeType(true);
        setEditingType(type);
        setType(type.type);
    };

    const handleUpdateCategory = async (id, newCategory) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateCategoryMaster/${id}`, { category: newCategory });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("Category Master and related Categories Successfully Updated");
                fetchMachineCategories();
                setEditModeCategory(false);
                setEditingCategory({});
            }
        } catch (error) {
            console.error('Error updating Category:', error);
            alert('Failed to update Category');
        }
    };
    const handleCategoryEditClick = (category) => {
        setEditModeCategory(true);
        setEditingCategory(category);
        setCategory(category.category);
    };

    const handleUpdateBrand = async (id, newBrand) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateBrandMaster/${id}`, { brand: newBrand });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("Brand Master and related Brands Successfully Updated");
                fetchMachineBrands();
                setEditModeBrand(false);
                setEditingBrand({});
            }
        } catch (error) {
            console.error('Error updating Brand:', error);
            alert('Failed to update Brand');
        }
    };
    const handleBrandEditClick = (brand) => {
        setEditModeBrand(true);
        setEditingBrand(brand);
        setBrand(brand.brand);
    };

    const handleUpdateWeight = async (id, newWeight) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateWeightMaster/${id}`, { weight: newWeight });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("Weight Master and related Weights Successfully Updated");
                fetchMachineWeights();
                setEditModeWeight(false);
                setEditingWeight({});
            }
        } catch (error) {
            console.error('Error updating Weight:', error);
            alert('Failed to update Weight');
        }
    };
    const handleWeightEditClick = (weight) => {
        setEditModeWeight(true);
        setEditingWeight(weight);
        setWeight(weight.weight);
    };

    const fetchMachineTypes = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineTypes")
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    setMachineTypes(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching machine types:", error);
            });
    };

    const fetchMachineCategories = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineCategory")
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    setMachineCategory(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching machine categories:", error);
            });
    };

    const fetchMachineBrands = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineBrand")
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    setMachineBrand(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching machine brands:", error);
            });
    };

    const fetchMachineWeights = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineWeight")
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    setMachineWeight(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching machine weight:", error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addmachineTypeName", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                type,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("Machine Type Successfully Added")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmitCategory = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addmachineCategoryName", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                category,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("Machine Category Successfully Added")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmitBrand = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addmachineBrandName", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                brand,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("Machine Brand Successfully Added")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmitWeight = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addmachineWeight", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                weight,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("Machine Weight Successfully Added")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };
    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };
    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/"
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li>
                                <a href="/machineMaster" className="active">
                                    <i className="fas fa-server icon"></i> Machine Master
                                </a>
                            </li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                            
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <>
                <section id="content">
                    <nav>
                        <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                    </nav>

                    <main>
                        <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link></li>
                            <li className="divider">/</li>
                            <li><Link to="#" className="active">Machine Master</Link></li>
                        </ul>
                        
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 10px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginBottom: "30px" }}>
                                    <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Machine Category</h1>
                                <div style={{ marginBottom: '1rem', width: '100%' }}>
                                    <label>Machine Category Name<span style={{ color: "red", fontWeight: "bolder" }}>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Machine Category"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setType(e.target.value)} required
                                    />
                                </div>

                                <div style={{ width: '100%' }}>
                                    <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                        Add Machine Category
                                    </button>
                                </div>
                            </form>


                            <form onSubmit={handleSubmitBrand} style={{ maxWidth: '400px', margin: '0 10px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginBottom: "30px" }}>
                                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Machine Brand</h1>
                                <div style={{ marginBottom: '1rem', width: '100%' }}>
                                    <label>Machine Brand Name<span style={{ color: "red", fontWeight: "bolder" }}>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Machine Brand"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setBrand(e.target.value)} required
                                    />
                                </div>

                                <div style={{ width: '100%' }}>
                                    <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                        Add Machine Brand
                                    </button>
                                </div>
                            </form>
                            <form onSubmit={handleSubmitCategory} style={{ maxWidth: '400px', margin: '0 10px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginBottom: "30px" }}>
                                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Machine Type</h1>
                                <div style={{ marginBottom: '1rem', width: '100%' }}>
                                    <label>Machine Type Name<span style={{ color: "red", fontWeight: "bolder" }}>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Machine Type"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setCategory(e.target.value)} required
                                    />
                                </div>

                                <div style={{ width: '100%' }}>
                                    <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                        Add Machine Type
                                    </button>
                                </div>
                            </form>

                            <form onSubmit={handleSubmitWeight} style={{ maxWidth: '400px', margin: '0 10px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginBottom: "30px" }}>
                                <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Machine Capacity</h1>
                                <div style={{ marginBottom: '1rem', width: '100%' }}>
                                    <label>Machine Capacity <span style={{ color: "red", fontWeight: "bolder" }}>(TON)*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Machine Capacity"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setWeight(e.target.value)}
                                        required
                                        step="any"
                                    />
                                </div>

                                <div style={{ width: '100%' }}>
                                    <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                        Add Machine Capacity
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* Machine Type */}

                        <div>
                            {editModeType && (
                                <div>
                                    <input
                                        type="text"
                                        value={editingType.type}
                                        onChange={(e) => setEditingType({ ...editingType, type: e.target.value })}
                                    />
                                    <button onClick={() => handleUpdateType(editingType._id, editingType.type)}>Save</button>
                                </div>
                            )}
                        </div>

                        <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Machine Category</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineTypes.map((type, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{type.type}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i className="fas fa-pencil-square" onClick={() => handleTypeEditClick(type)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Machine Category */}

                        <div>
                            {editModeCategory && (
                                <div>
                                    <input
                                        type="text"
                                        value={editingCategory.category}
                                        onChange={(e) => setEditingCategory({ ...editingCategory, category: e.target.value })}
                                    />
                                    <button onClick={() => handleUpdateCategory(editingCategory._id, editingCategory.category)}>Save</button>
                                </div>
                            )}
                        </div>

                        {/* Machine Category Table */}
                        <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Machine Type</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineCategory.map((category, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{category.category}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i className="fas fa-pencil-square" onClick={() => handleCategoryEditClick(category)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Machine Brand */}

                        <div>
                            {editModeBrand && (
                                <div>
                                    <input
                                        type="text"
                                        value={editingBrand.brand}
                                        onChange={(e) => setEditingBrand({ ...editingBrand, brand: e.target.value })}
                                    />
                                    <button onClick={() => handleUpdateBrand(editingBrand._id, editingBrand.brand)}>Save</button>
                                </div>
                            )}
                        </div>

                        {/* Brand table content */}
                        <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineBrand.map((brand, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{brand.brand}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i className="fas fa-pencil-square" onClick={() => handleBrandEditClick(brand)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Machine Weight */}

                        <div>
                            {editModeWeight && (
                                <div>
                                    <input
                                        type="text"
                                        value={editingWeight.weight}
                                        onChange={(e) => setEditingWeight({ ...editingWeight, weight: e.target.value })}
                                    />
                                    <button onClick={() => handleUpdateWeight(editingWeight._id, editingWeight.weight)}>Save</button>
                                </div>
                            )}
                        </div>

                        {/* Weight table content */}
                        <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineWeight.map((weight, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{weight.weight}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i className="fas fa-pencil-square" onClick={() => handleWeightEditClick(weight)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </main>
                </section>
            </>
            {/* NAVBAR */}
        </>
    );
}