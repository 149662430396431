import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const SupervisorStockReport = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(true);
    const [supervisors, setSupervisors] = useState([]);
    const [clients, setClients] = useState([]);
    const [machines, setMachines] = useState([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedMachine, setSelectedMachine] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        // Fetch supervisors list on component mount
        axios.get('https://jubilant-backend.netlysystems.com/stock-supervisors-reportName')
            .then(res => setSupervisors(res.data))
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        // Fetch clients when a supervisor is selected or All is selected
        if (selectedSupervisor || selectedSupervisor === 'All') {
            axios.get(`https://jubilant-backend.netlysystems.com/stock-clients/${selectedSupervisor}`)
                .then(res => setClients(res.data))
                .catch(err => console.error(err));
        }
    }, [selectedSupervisor]);

    useEffect(() => {
        // Fetch machines when a client is selected or All is selected
        if (selectedClient || selectedClient === 'All') {
            const supervisorName = selectedSupervisor === '' ? 'All' : selectedSupervisor;
            axios.get(`https://jubilant-backend.netlysystems.com/stock-machines/${selectedClient}?supervisorName=${supervisorName}`)
                .then(res => setMachines(res.data))
                .catch(err => console.error(err));
        }
    }, [selectedClient, selectedSupervisor]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/"
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/";
        return null;
    }

    const handleSupervisorChange = (e) => {
        const value = e.target.value;
        setSelectedSupervisor(value);
        setSelectedClient('');
        setSelectedMachine('');
        setClients([]);
        setMachines([]);
        if (value === 'All') {
            axios.get('https://jubilant-backend.netlysystems.com/stock-clients/All')
                .then(res => setClients(res.data))
                .catch(err => console.error(err));
            axios.get('https://jubilant-backend.netlysystems.com/stock-machines/All')
                .then(res => setMachines(res.data))
                .catch(err => console.error(err));
        }
    };

    const handleClientChange = (e) => {
        const value = e.target.value;
        setSelectedClient(value);
        setSelectedMachine('');
        setMachines([]);
        if (value === 'All') {
            const supervisorName = selectedSupervisor === '' ? 'All' : selectedSupervisor;
            axios.get(`https://jubilant-backend.netlysystems.com/stock-machines/All?supervisorName=${supervisorName}`)
                .then(res => setMachines(res.data))
                .catch(err => console.error(err));
        }
    };

    const handleMachineChange = (e) => {
        setSelectedMachine(e.target.value);
    };

    const handleStartDateChange = (e) => setStartDate(e.target.value);
    const handleEndDateChange = (e) => setEndDate(e.target.value);

    const generateReport = async () => {
        try {
            const response = await axios.post('https://jubilant-backend.netlysystems.com/supervisor-stock-wise-report', {
                supervisorName: selectedSupervisor === '' ? 'All' : selectedSupervisor,
                clientName: selectedClient === '' ? 'All' : selectedClient,
                sno: selectedMachine === '' ? 'All' : selectedMachine,
                startDate,
                endDate,
            }, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `stock_issue_report_for_${selectedSupervisor}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            const blob = new Blob(['No report found'], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `stock_issue_report_for_${selectedSupervisor}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        generateReport();
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>

                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li>
                                <a href="/supervisor-stock-report" className="active">
                                    <i className="fas fa-ticket icon"></i>Stock Issue Report
                                </a>
                            </li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisor-stock-report">Stock Issue Report</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Stock Issue Report :</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Supervisor Name</label>
                                <select style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }} value={selectedSupervisor} onChange={handleSupervisorChange}>
                                    <option value="">Select Supervisor</option>
                                    <option value="All">All</option>
                                    {supervisors.map((supervisorName, index) => (
                                        <option key={index} value={supervisorName}>{supervisorName}</option>
                                    ))}
                                </select>

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Client</label>
                                <select style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }} value={selectedClient} onChange={handleClientChange}>
                                    <option value="">Select Client</option>
                                    <option value="All">All</option>
                                    {clients.map((clientName, index) => (
                                        <option key={index} value={clientName}>{clientName}</option>
                                    ))}
                                </select>

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Machine S.No</label>
                                <select style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }} value={selectedMachine} onChange={handleMachineChange}>
                                    <option value="">Select Machine S.No</option>
                                    <option value="All">All</option>
                                    {machines.map((sno, index) => (
                                        <option key={index} value={sno}>{sno}</option>
                                    ))}
                                </select>

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Start Date</label>
                                <input style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }} type="date" value={startDate} onChange={handleStartDateChange} required />

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>End Date</label>
                                <input style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }} type="date" value={endDate} onChange={handleEndDateChange} required />

                                <button type="submit">Generate Report</button>
                            </div>
                        </form>
                    </div>
                </main>
            </section >
            {/* NAVBAR */}
        </>
    )
}

export default SupervisorStockReport;