import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const InventoryForm = () => {
    const [formData, setFormData] = useState({
        godownMasterId: "",
        productNameMasterId: "",
        productName: "",
        machineBrandMasterId: "",
        brand: "",
        vendorMasterId: "",
        date: "",
    });
    const [machineBrandMasterId, setBrandMasterId] = useState("");
    const [brand, setBrand] = useState("");
    const [machineBrand, setMachineBrand] = useState([]);
    const [gnameList, setGnameList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchProduct();
        fetchGodownFromToken();
        fetchMachineBrand();
        fetchGodown();
        fetchVendor();
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await fetch("https://jubilant-backend.netlysystems.com/productNames");
            const data = await response.json();
            setProductList(data);
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    };

    const fetchGodownFromToken = () => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setFormData((prevData) => ({
                    ...prevData,
                    godownMasterId: decodedToken.godownMasterId,
                }));
            } catch (error) {
                console.error("Failed to decode token:", error);
            }
        }
    };

    const fetchMachineBrand = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineBrand")
            .then((res) => res.json())
            .then((data) => setMachineBrand(data))
            .catch((error) => console.error(error));
    };

    const fetchGodown = () => {
        fetch("https://jubilant-backend.netlysystems.com/godownNames")
            .then((res) => res.json())
            .then((data) => setGnameList(data))
            .catch((error) => console.error(error));
    };

    const fetchVendor = () => {
        fetch("https://jubilant-backend.netlysystems.com/vendorNames")
            .then((res) => res.json())
            .then((data) => setVendorList(data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleProductSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setFormData((prevData) => ({ ...prevData, productName: query }));

        if (query.trim() === "") {
            setSearchedProducts([]);
        } else {
            const filteredProducts = productList.filter((product) =>
                product.productName.toLowerCase().includes(query)
            );
            setSearchedProducts(filteredProducts);
        }
        setShowDropdown(true);
    };

    const handleProductSelect = (product) => {
        setFormData({
            ...formData,
            productNameMasterId: product._id,
            productName: product.productName,
        });
        setSearchedProducts([]);
        setShowDropdown(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.productNameMasterId) {
            alert("Please select a product.");
            return;
        }
        try {
            await axios.post("https://jubilant-backend.netlysystems.com/Addinventory", formData);
            alert("Item added successfully!");
            window.location.reload();
        } catch (error) {
            alert("Error adding item. Please try again.");
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <form
                style={{
                    margin: '0 auto',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: "white",
                    maxWidth: '1000px'
                }}
                onSubmit={handleSubmit}
                className="machine-form"
            >
                <h1
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        color: '#333'
                    }}
                >
                    Spare Parts Inventory
                </h1>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Product Name:
                    <div style={{ position: 'relative' }}>
                        <input
                            className="form-select"
                            type="text"
                            name="productName"
                            value={formData.productName}
                            onChange={handleProductSearch}
                            placeholder="Search Product..."
                            style={{ width: '100%' }}
                        />
                        {showDropdown && (
                            <ul
                                ref={dropdownRef}
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    width: '100%',
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                    border: '1px solid #ddd',
                                    backgroundColor: '#fff',
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0
                                }}
                            >
                                {(searchedProducts.length > 0 ? searchedProducts : productList).map(product => (
                                    <li
                                        key={product._id}
                                        onClick={() => handleProductSelect(product)}
                                        style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
                                    >
                                        {product.productName}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Date:
                    <input
                        className="form-select"
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        style={{ width: '100%', padding: '8px' }}
                        required
                    />
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Brand:
                    <select
                        className="form-select"
                        style={{ width: '100%' }}
                        name="machineBrandMasterId"
                        value={formData.machineBrandMasterId}
                        onChange={(e) => {
                            const selectedBrandId = e.target.value;
                            const selectedBrand = machineBrand.find(b => b._id === selectedBrandId);
                            setFormData({
                                ...formData,
                                machineBrandMasterId: selectedBrandId,
                                brand: selectedBrand ? selectedBrand.brand : ''
                            });
                        }}
                        required
                    >
                        <option value="">Select Machine Brand</option>
                        {machineBrand.map((brand) => (
                            <option key={brand._id} value={brand._id}>
                                {brand.brand}
                            </option>
                        ))}
                    </select>
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Vendor Name:
                    <select
                        className="form-select"
                        style={{ width: '100%' }}
                        name="vendorMasterId"
                        value={formData.vendorMasterId}
                        onChange={(e) => setFormData({ ...formData, vendorMasterId: e.target.value })}
                        required
                    >
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendormaster) => (
                            <option key={vendormaster._id} value={vendormaster._id}>
                                {vendormaster.vendor}
                            </option>
                        ))}
                    </select>
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Location:
                    <select
                        className="form-select"
                        style={{ width: '100%' }}
                        name="godownMasterId"
                        value={formData.godownMasterId}
                        onChange={(e) => setFormData({ ...formData, godownMasterId: e.target.value })}
                        disabled
                        required
                    >
                        {gnameList.map((godownmaster) => (
                            <option key={godownmaster._id} value={godownmaster._id}>
                                {godownmaster.gname}
                            </option>
                        ))}
                    </select>
                </label>
                <button
                    style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        padding: '10px 20px',
                        fontSize: '15px',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        marginTop: '20px'
                    }}
                    type="submit"
                >
                    Add Item to Inventory
                </button>
            </form>
        </div>
    );
};

export default InventoryForm;