import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SupervisorRequestForm from './SupervisorRequestForm';
import './css/DashboardHome.css';

const SupervisorRequestPage = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [requests, setRequests] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 30; // Show users per page

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // State to manage the visibility of the Request form
    const [showRequestForm, setShowRequestForm] = useState(false);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const fetchRequests = async () => {
        try {
            const token = window.localStorage.getItem("token");
            const response = await fetch('https://jubilant-backend.netlysystems.com/suprequests', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch requests');
            }

            const data = await response.json();
            setRequests(data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/";
        return null;
    }

    // Function to format date
        const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
        };

    const filteredRequests = requests.filter((request) =>
        (request.brand?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.sno?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.clientName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.status?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.productName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase())
    );

    const indexOfLastUser = Math.min(currentPage * usersPerPage, filteredRequests.length);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to="/supervisorMachine"><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li>
                        <Link to="/supervisorRequest" className="active">
                            <i className="fas fa-ticket icon"></i> Requests
                        </Link>
                    </li>
                    <li><Link to="/supervisorInspection"><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to="/supervisorDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li><Link to="/supervisorInventory"><i className="fas fa-store icon"></i>Supervisor Inventory</Link></li>
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Supervisor</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisorRequest">Requests</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Requests :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Site, Product Name, Brand, S.No. and Status..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {filteredRequests.length === 0 && <p>No requests found</p>}
                        <button style={{ marginBottom: '10px', borderRadius: '10px' }} className="add-user-btn" onClick={() => setShowRequestForm(true)}>
                            <i className="fas fa-plus-circle"></i> Request Item
                        </button>
                        {showRequestForm && <SupervisorRequestForm />}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Requested Date</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Serial No.</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Requested Quantity</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Received Quantity</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Remaining Quantity</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRequests.slice().reverse().slice(indexOfFirstUser, indexOfLastUser).map((request, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstUser + index + 1}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{formatDate(request.createdAt)}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.productName}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.clientName}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.brand}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.sno}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.requestedQuantity}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.sentQuantity}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.remainingQuantity}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)} disabled={currentPage === 1}>
                                Previous Page
                            </button>
                            <button onClick={() => paginate(currentPage + 1)} disabled={indexOfLastUser >= filteredRequests.length}>
                                Next Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default SupervisorRequestPage;