import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./css/DashboardHome.css";

const SupervisorInspection = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const [images, setImages] = useState([]);
    const [machines, setMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [clientName, setClientName] = useState('');

    useEffect(() => {
        const fetchMachines = async () => {
            try {
                const token = window.localStorage.getItem('token');
                const response = await axios.get('https://jubilant-backend.netlysystems.com/supervisor-clients-machines', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMachines(response.data.machines);
            } catch (error) {
                console.error('Error fetching machines:', error);
            }
        };
        fetchMachines();
    }, []);

    const handleMachineChange = async (e) => {
        const selectedMachineNo = e.target.value;
        setSelectedMachine(selectedMachineNo);

        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `https://jubilant-backend.netlysystems.com/machine-client-name/${selectedMachineNo}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setClientName(response.data.clientName);
        } catch (error) {
            console.error('Error fetching client name:', error);
            setClientName('');
        }
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/"
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if at least one image is selected
        if (images.length === 0 || images.every(image => image === null)) {
            alert("Please select at least one image.");
            return;
        }

        const formData = new FormData();
        images.forEach((image) => {
            if (image) {
                formData.append("images", image);
            }
        });
        formData.append("sno", selectedMachine);
        formData.append("clientName", clientName);

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                "https://jubilant-backend.netlysystems.com/inspection/upload",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert("Files uploaded successfully");
            window.location.reload()
        } catch (error) {
            console.error(error);
            alert("Failed to upload Files");
        }
    };

    const handleImageChange = (e, index) => {
        const files = e.target.files;
        const newImages = [...images];
        newImages[index] = files[0];
        setImages(newImages);
    };

    const addImageRow = () => {
        setImages([...images, null]);
    };

    const removeImageRow = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to="/supervisorMachine"><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to="/supervisorRequest"><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li>
                        <Link to="/supervisorInspection" className="active">
                            <i className="fas fa-street-view icon"></i> Inspection
                        </Link>
                    </li>
                    <li><Link to="/supervisorDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li><Link to="/supervisorInventory"><i className="fas fa-store icon"></i>Supervisor Inventory</Link></li>
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title">Hello Supervisor,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisorInspection">Inspection</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ margin: "20px", padding: "20px", border: "1px solid #ccc", borderRadius: "5px", backgroundColor: '#F1F0F6', boxShadow: "0 2px 4px rgba(0,0,0,0.1)", width: '80%' }}>
                            <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>Inspection Page</h1>
                            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <label style={{ marginBottom: "10px", fontSize: "1.1em", fontWeight: "bold", color: "#333" }}>
                                    Machine Serial Number:
                                </label>
                                <select
                                    className="form-select"
                                    value={selectedMachine}
                                    onChange={handleMachineChange}
                                    required
                                    style={{ marginBottom: "20px", padding: "5px", borderRadius: "3px", border: "1px solid #ccc", width: "180px", boxSizing: "border-box" }}
                                >
                                    <option value="">Select Machine</option>
                                    {machines.map((machine, index) => (
                                        <option key={index} value={machine.sno}>
                                            {machine.sno}
                                        </option>
                                    ))}
                                </select>
                                <label style={{ marginBottom: "10px", fontSize: "1.1em", fontWeight: "bold", color: "#333" }}>
                                    Client Name:
                                </label>
                                <input
                                    type="text"
                                    value={clientName}
                                    readOnly
                                    style={{ marginBottom: "20px", padding: "5px", borderRadius: "3px", border: "1px solid #ccc", width: "180px", boxSizing: "border-box" }}
                                />
                                <label style={{ marginBottom: "10px", fontSize: "1.1em", fontWeight: "bold", color: "#333" }}>
                                    Upload Images (up to 15):
                                </label>
                                {images.map((image, index) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e, index)}
                                            style={{ marginBottom: "10px", padding: "5px", borderRadius: "3px", border: "1px solid #ccc", width: "180px", boxSizing: "border-box" }}
                                            required
                                        />
                                        <button type="button" onClick={() => removeImageRow(index)} style={{ backgroundColor: "#ff4d4d", color: "white", padding: "5px 10px", border: "none", borderRadius: "3px", cursor: "pointer" }}>
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                {images.length < 15 && (
                                    <button
                                        type="button"
                                        onClick={addImageRow}
                                        required
                                        style={{ marginBottom: "20px", backgroundColor: "#007bff", color: "white", padding: "8px 20px", border: "none", borderRadius: "3px", cursor: "pointer", alignSelf: "center" }}
                                    >
                                        Add Image
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    style={{ backgroundColor: "#007bff", color: "white", padding: "8px 20px", border: "none", borderRadius: "3px", cursor: "pointer", alignSelf: "center" }}
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default SupervisorInspection;