import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Login extends Component {
  constructor(props){
    super(props)
    this.state={
      phone:"",
      password:""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e){
    e.preventDefault();
    const { phone,password } = this.state;
    fetch("https://jubilant-backend.netlysystems.com/login-user", {
      method: "POST",
      crossDomain: true,
      headers:{
        "Content-Type":"application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body:JSON.stringify({
        phone,
        password,
      }),
    })
    .then((res)=>res.json())
    .then((data)=>{
      if(data.status === "Ok"){
        alert("login Successfully");
        window.localStorage.setItem("token", data.data);
        window.localStorage.setItem("loggedIn", true);

        window.location.href="/"
      }
      else {
        alert(data.error);
      }
    });
  }
  render() {
    return (
      <div className="container-fluid vh-100 d-flex justify-content-center align-items-center bg-light bg-image">
        <div className="card p-4">
          <div className="card-header bg-primary text-white text-center">Login</div>
          <div className="card-body">
            <form onSubmit={this.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Contact Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder='Enter Contact Number'
                  onChange={(e) => this.setState({ phone: e.target.value })} required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter Password"
                  onChange={(e) => this.setState({ password: e.target.value })} required
                />
              </div>
              <button type="submit" className="btn btn-primary">Login</button>
              <Link to={'/reset'} className="btn btn-link">Forgot Password?</Link>
            </form>
          </div>
          <div className="card-footer text-center">
            Don't have an account? <Link to="/sign-up">Sign up</Link>
          </div>
        </div>
      </div>
    )
  }
}