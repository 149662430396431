import React, { useEffect, useState } from "react";
import "./css/DashboardHome.css";
import image from './css/forklift.jpg';
import { Link } from "react-router-dom";
import axios from 'axios';

export default function SuperAdminHome({ userData }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";

  const [counts, setCounts] = useState({
    users: 0,
    clients: 0,
    machines: 0
  });

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const usersResponse = await axios.get('https://jubilant-backend.netlysystems.com/users/count');
        const clientsResponse = await axios.get('https://jubilant-backend.netlysystems.com/clients/count');
        const machinesResponse = await axios.get('https://jubilant-backend.netlysystems.com/machines/count');

        setCounts({
          users: usersResponse.data.count,
          clients: clientsResponse.data.count,
          machines: machinesResponse.data.count
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content")
    window.location.href = "/";
    return null;
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const toggleMaster = () => {
    setIsMasterOpen(!isMasterOpen);
  };
  const toggleReport = () => {
    setIsReportOpen(!isReportOpen);
  };
  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <a href="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </a>
        <ul className="side-menu">
          <li>
            <a href="/" className="active">
              <i className="fas fa-dashboard icon"></i> Dashboard
            </a>
          </li>
          <li className="divider" data-text="Master" onClick={toggleMaster}>
            Master
            <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isMasterOpen && (
            <>
              <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
              <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
              <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
              <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
              <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
              <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
              <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
              <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
            </>
          )}
          <li className="divider" data-text="main">
            Main
          </li>
          <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
          <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
          <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
          <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
          <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
          <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
          <li className="divider" data-text="Inventory">Inventory</li>
          <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
          <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
          <li className="divider" data-text="Report" onClick={toggleReport}>
            Report
            <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isReportOpen && (
            <>
              <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
              <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
              <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
              <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
              <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
              <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
              <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
              <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
              <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
              <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
            </>
          )}
        </ul>

        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> {userData.fname} Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title" style={{ marginLeft: "20px" }}>Hello {userData.fname}</h4>
          <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li className="divider">/</li>
            <li><Link to="#" className="active">Dashboard</Link></li>
          </ul>
          <ul className="box-info">
            <li>
              <i className="fas fa-user"></i>
              <span className="text">
                <p>Total Users:</p>
                <h3>{counts.users}</h3>
              </span>
            </li>
            <li>
              <i className="fas fa-users"></i>
              <span className="text">
                <p>Total Clients:</p>
                <h3>{counts.clients}</h3>
              </span>
            </li>
            <li>
              <i className="fas fa-server"></i>
              <span className="text">
                <p>Total Machines:</p>
                <h3>{counts.machines}</h3>
              </span>
            </li>
          </ul>
          <div className="image-container">
            <img className="forklift" src={image} alt="Forklift" />
          </div>
        </main>
      </section>
      {/* NAVBAR */}
    </>
  );
}