import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Login from './components/login.component'
import SignUp from './components/signup.component'
import UserDetails from './components/userDetails'
import UserDashboard from './components/UserDashboard';
import Reset from './components/reset'
import '@fortawesome/fontawesome-free/css/all.min.css';
import ClientDashboard from './components/ClientDashboard';
import MachineDashboard from './components/MachineDashboard';
import InventoryDashboard from './components/InventoryDashboard';
import StorekeeperRequest from './components/StorekeeperRequest';
import SupervisorRequestPage from './components/SupervisorRequestPage';
import SuperAdminRequest from './components/SuperAdminRequest';
import SupervisorMachine from './components/SupervisorMachine';
import SupervisorInspection from './components/SupervisorInspection';
import SuperAdminInspection from './components/SuperAdminInspection';
import SuperAdminTransferMachineDashboard from './components/SuperAdminTransferMachineDashboard';
import StorekeeperInventory from './components/StorekeeperInventory';
import AdminUserHome from './components/AdminUserHome';
import AdminClientHome from './components/AdminClientHome';
import AdminMachine from './components/AdminMachine';
import AdminInventory from './components/AdminInventory';
import StaffClient from './components/StaffClient';
import StaffMachine from './components/StaffMachine';
import ClientMaster from './components/ClientMaster';
import MachineMaster from './components/MachineMaster';
import GodownMaster from './components/GodownMaster';
import CompanyMaster from './components/CompanyMaster';
import VendorMaster from './components/VendorMaster';
import InventoryMaster from './components/InventoryMaster';
import SuperAdminDeadStock from './components/SuperAdminDeadStock';
import SupervisorDeadStock from './components/SupervisorDeadStock';
import InspectionReport from './components/InspectionReport';
import InventoryReport from './components/InventoryReport';
import MachineLoanReport from './components/MachineLoanReport';
import TransferReport from './components/TransferReport';
import SupervisorStockReport from './components/SupervisorStockReport';
import MachineWiseReport from './components/MachineWiseReport';
import SupervisorInventory from './components/SupervisorInventory';
import SupervisorInventoryReport from './components/SupervisorInventoryReport';
import StorekeeperDeadStock from './components/StorekeeperDeadStock';
import AdminInspection from './components/AdminInspection';
import AdminTransferMachine from './components/AdminTransferMachine';
import AdminDeadStock from './components/AdminDeadStock';
import AdminRequest from './components/AdminRequest';
import StaffTransferMachine from './components/StaffTransferMachine';
import StateMaster from './components/StateMaster';
import BatteryMaster from './components/BatteryMaster';
import MachineDetailReport from './components/MachineDetailReport';
import MachineConsolidatedReport from './components/MachineConsolidatedReport';
import AdminMachineDetailReport from './components/AdminMachineDetailReport';
import AdminMachineConsolidatedReport from './components/AdminMachineConsolidatedReport';
import StaffMachineDetailReport from './components/StaffMachineDetailReport';
import StaffMachineConsolidatedReport from './components/StaffMachineConsolidatedReport';
import StorekeeperUsageReport from './components/StorekeeperUsageReport';
import AdminRequestApprovals from './components/AdminRequestApprovals';

function App() {
  const token = window.localStorage.getItem("token");
  const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : {};
  const userType = decodedToken.userType;
  const isLoggedIn = token !== null && token !== undefined;

  return (
    <Router>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Routes>
            <Route path="/" element={<Navigate to={isLoggedIn ? "/userDetails" : "/sign-in"} />} />
            <Route path="/sign-in" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/userDetails" element={<UserDetails />} />
            {userType === "Supervisor" && (
              <>
                <Route path="/supervisorRequest" element={<SupervisorRequestPage />} />
                <Route path="/supervisorMachine" element={<SupervisorMachine />} />
                <Route path="/supervisorInspection" element={<SupervisorInspection />} />
                <Route path="/supervisorDeadStock" element={<SupervisorDeadStock />} />
                <Route path="/supervisorInventory" element={<SupervisorInventory />} />
              </>
            )}
            {userType === "SuperAdmin" && (
              <>
                <Route path="/userdashboard" element={<UserDashboard />} />
                <Route path="/clientdashboard" element={<ClientDashboard />} />
                <Route path="/machinedashboard" element={<MachineDashboard />} />
                <Route path="/inventorydashboard" element={<InventoryDashboard />} />
                <Route path="/supervisorAllRequests" element={<SuperAdminRequest />} />
                <Route path="/superAdminInspection" element={<SuperAdminInspection />} />
                <Route path="/transferMachine" element={<SuperAdminTransferMachineDashboard />} />
                <Route path="/clientMaster" element={<ClientMaster />} />
                <Route path="/machineMaster" element={<MachineMaster />} />
                <Route path="/godownMaster" element={<GodownMaster />} />
                <Route path="/companyMaster" element={<CompanyMaster />} />
                <Route path="/vendorMaster" element={<VendorMaster />} />
                <Route path="/inventoryMaster" element={<InventoryMaster />} />
                <Route path="/stateMaster" element={<StateMaster />} />
                <Route path="/batteryMaster" element={<BatteryMaster />} />
                <Route path="/deadStock-SA" element={<SuperAdminDeadStock />} />
                <Route path="/inspection-report" element={<InspectionReport />} />
                <Route path="/inventory-report" element={<InventoryReport />} />
                <Route path="/machine-loan-report" element={<MachineLoanReport />} />
                <Route path="/machine-transfer-report" element={<TransferReport />} />
                <Route path="/supervisor-stock-report" element={<SupervisorStockReport />} />
                <Route path="/machine-stock-report" element={<MachineWiseReport />} />
                <Route path="/supervisor-inventory-report" element={<SupervisorInventoryReport />} />
                <Route path="/machine-detail-report" element={<MachineDetailReport />} />
                <Route path="/machine-consolidated-report" element={<MachineConsolidatedReport />} />
                <Route path="/storekeeper-usage-report" element={<StorekeeperUsageReport />} />
              </>
            )}
            {userType === "Storekeeper" && (
              <>
                <Route path="/storekeeperRequest" element={<StorekeeperRequest />} />
                <Route path="/storekeeperInventory" element={<StorekeeperInventory />} />
                <Route path="/storekeeperDeadStock" element={<StorekeeperDeadStock />} />
              </>
            )}
            {userType === "Admin" && (
              <>
                <Route path="/adminUser" element={<AdminUserHome />} />
                <Route path="/adminClient" element={<AdminClientHome />} />
                <Route path="/adminMachine" element={<AdminMachine />} />
                <Route path="/adminRequestApprovals" element={<AdminRequestApprovals />} />
                <Route path="/adminRequest" element={<AdminRequest />} />
                <Route path="/adminInspection" element={<AdminInspection />} />
                <Route path="/adminTransferMachine" element={<AdminTransferMachine />} />
                <Route path="/adminInventory" element={<AdminInventory />} />
                <Route path="/adminDeadStock" element={<AdminDeadStock />} />
                <Route path="/admin-machine-detail-report" element={<AdminMachineDetailReport />} />
                <Route path="/admin-machine-consolidated-report" element={<AdminMachineConsolidatedReport />} />
              </>
            )}
            {userType === "Staff" && (
              <>
                <Route path="/staffClient" element={<StaffClient />} />
                <Route path="/staffMachine" element={<StaffMachine />} />
                <Route path="/staffTransferMachine" element={<StaffTransferMachine />} />
                <Route path="/staff-machine-detail-report" element={<StaffMachineDetailReport />} />
                <Route path="/staff-machine-consolidated-report" element={<StaffMachineConsolidatedReport />} />
              </>
            )}
            <Route path="/reset" element={<Reset />} />
            <Route path="/*" element={<div> <h1>404 !! Page Not Found!</h1></div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;