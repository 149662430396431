import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./css/DashboardHome.css";

const SupervisorDeadStock = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productList, setProductList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const response = await axios.get("https://jubilant-backend.netlysystems.com/deadStock/products");
        setProductList(response.data);
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };

    fetchProductList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content");
    window.location.href = "/";
    return null;
  }

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const handleProductNameInput = (e) => {
    const input = e.target.value;
    setProductName(input);
    const filtered = productList.filter(
      (product) =>
        product.productName.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleProductSelection = (productName) => {
    setProductName(productName);
    setShowDropdown(false);
    setFilteredProducts([]);
  };

  const handleInputClick = () => {
    setFilteredProducts(productList);
    setShowDropdown(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if productName is empty
    if (!productName) {
      alert('Please select a product name from the dropdown.');
      return;
    }

    // Check if the productName is in the productList
    const selectedProduct = productList.find(
      (product) => product.productName === productName
    );

    if (!selectedProduct) {
      alert('Please select a Product name from the dropdown.');
      return;
    }

    // Proceed with form submission
    const newDeadStockItem = {
      productName,
      returnDate,
      quantity,
    };

    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://jubilant-backend.netlysystems.com/deadStock",
        newDeadStockItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        alert("Dead stock item added successfully");
        setProductName("");
        setReturnDate("");
        setQuantity("");
      }
    } catch (error) {
      console.error("There was an error adding the dead stock item:", error);
    }
  };

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <Link to="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </Link>
        <ul className="side-menu">
          <li>
            <Link to="/">
              <i className="fas fa-dashboard icon"></i>Dashboard
            </Link>
          </li>
          <li className="divider" data-text="main">
            Main
          </li>
          <li>
            <Link to="/supervisorMachine">
              <i className="fas fa-server icon"></i>Machines
            </Link>
          </li>
          <li>
            <Link to="/supervisorRequest">
              <i className="fas fa-ticket icon"></i>Requests
            </Link>
          </li>
          <li>
            <Link to="/supervisorInspection">
              <i className="fas fa-street-view icon"></i>Inspection
            </Link>
          </li>
          <li>
            <Link to="/supervisorDeadStock" className="active">
              <i className="fas fa-window-close icon"></i> Dead Stock
            </Link>
          </li>
          <li>
            <Link to="/supervisorInventory">
              <i className="fas fa-store icon"></i>Supervisor Inventory
            </Link>
          </li>
        </ul>
        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title">Hello Supervisor,</h4>
          <ul className="breadcrumbs">
            <li>
              <Link to="/supervisorDeadStock">Dead Stock</Link>
            </li>
            <li className="divider">/</li>
            <li>
              <Link to="#" className="active">
                Dashboard
              </Link>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                margin: "20px",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                width: "80%",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "#333",
                }}
              >
                Dead Stock
              </h1>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "400px",
                  margin: "0 auto",
                  padding: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  backgroundColor: "white",
                }}
              >
                <label
                  htmlFor="productName"
                  style={{
                    marginBottom: "8px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Enter Product Name:
                </label>
                <div
                  ref={dropdownRef}
                  style={{
                    position: "relative",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="text"
                    value={productName}
                    onChange={handleProductNameInput}
                    onClick={handleInputClick}
                    required
                    style={{
                      padding: "8px",
                      fontSize: "15px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  />
                  {showDropdown && filteredProducts.length > 0 && (
                    <ul
                      className="product-dropdown"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        width: "100%",
                        maxHeight: "150px",
                        overflowY: "auto",
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        borderTop: "none",
                        zIndex: "10",
                        borderRadius: "0 0 5px 5px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        padding: "0",
                        margin: "0",
                        listStyle: "none",
                      }}
                    >
                      {filteredProducts.map((product, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleProductSelection(product.productName)
                          }
                          style={{
                            cursor: "pointer",
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          {product.productName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <label
                  htmlFor="returnDate"
                  style={{
                    marginBottom: "8px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Enter Return Date:
                </label>
                <input
                  type="date"
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                  required
                  style={{
                    marginBottom: "20px",
                    padding: "8px",
                    fontSize: "15px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />

                <label
                  htmlFor="quantity"
                  style={{
                    marginBottom: "8px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Enter Quantity:
                </label>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                  style={{
                    marginBottom: "20px",
                    padding: "8px",
                    fontSize: "15px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    padding: "8px",
                    fontSize: "15px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Submit Request
                </button>
              </form>
            </div>
          </div>
        </main>
      </section>
      {/* NAVBAR */}
    </>
  );
};

export default SupervisorDeadStock;