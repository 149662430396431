import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import EditStock from './EditStock';

const InventoryDashboard = () => {
  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [godown, setGodown] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [productName, setProductName] = useState([]); // Ensure product names are stored here

  // State to track the inventory to be edited
  const [editUser, setEditUser] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleMaster = () => {
    setIsMasterOpen(!isMasterOpen);
  };

  const toggleReport = () => {
    setIsReportOpen(!isReportOpen);
  };

  const usersPerPage = 30; // Show only 30 users per page

  const fetchData = useCallback(() => {
    fetch("https://jubilant-backend.netlysystems.com/getAllInventory", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        // Sort data in descending order based on 'quantity' stock
        const sortedData = data.data.sort((a, b) => b.quantity - a.quantity);
        setData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // Fetch godown data
    fetch("https://jubilant-backend.netlysystems.com/getAllGodowns", {
      method: "GET",
    }).then((res) => res.json())
      .then((data) => {
        setGodown(data.data);
      }).catch((error) => {
        console.error('Error fetching godowns:', error);
      });
  }, []);

  // Function to get godown name based on godown ID
  const getGodownName = (godownId) => {
    const godownData = godown.find(g => g._id === godownId);
    return godownData ? godownData.gname : 'Unknown';
  };

  useEffect(() => {
    // Fetch vendor data
    fetch("https://jubilant-backend.netlysystems.com/getAllVendors", {
      method: "GET",
    }).then((res) => res.json())
      .then((data) => {
        setVendor(data.data);
      }).catch((error) => {
        console.error('Error fetching vendors:', error);
      });
  }, []);

  // Function to get vendor name based on vendor ID
  const getVendorName = (vendorId) => {
    const vendorData = vendor.find(v => v._id === vendorId);
    return vendorData ? vendorData.vendor : 'Unknown';
  };

  // Fetch product names
  useEffect(() => {
    fetch("https://jubilant-backend.netlysystems.com/getAllProductNames", {
      method: "GET",
    }).then((res) => res.json())
      .then((data) => {
        setProductName(data.data);
      }).catch((error) => {
        console.error('Error fetching product names:', error);
      });
  }, []);

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  // Function to get product name based on product ID
  const getProductName = (productNameId) => {
    const product = productName.find(p => p._id === productNameId);
    return product ? product.productName : 'Unknown';
  };

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content")
    window.location.href = "/";
    return null;
  }

  let filteredData = data;
  if (searchTerm.trim() !== '') {
    filteredData = data.filter(user => {
      const searchTerms = searchTerm.toLowerCase().split(' ');
      return searchTerms.every(term =>
        user.productName.toLowerCase().includes(term) ||
        user.brand.toLowerCase().includes(term) ||
        getVendorName(user.vendorMasterId).toLowerCase().includes(term) ||
        getGodownName(user.godownMasterId).toLowerCase().includes(term)
      );
    });
  }

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };

  const totalPages = Math.ceil(filteredData.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to set the inventory to be edited
  const handleEditUser = (user) => {
    setEditUser(user);
  };

  const updateInventory = (updatedInventory) => {
    fetch(`https://jubilant-backend.netlysystems.com/updateStock/${updatedInventory.productNameMasterId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedInventory),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Inventory updated successfully') {
          alert("Inventory updated successfully");
          fetchData(); // Refresh the data after updating
        } else {
          alert("Error updating inventory: " + (data.message || "Unknown error"));
        }
      })
      .catch((error) => {
        console.error("Error updating inventory:", error);
        alert("Error updating inventory");
      });
  };

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <a href="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </a>
        <ul className="side-menu">
          <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
          <li className="divider" data-text="Master" onClick={toggleMaster}>
            Master
            <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isMasterOpen && (
            <>
              <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
              <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
              <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
              <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
              <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
              <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
              <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
              <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
            </>
          )}
          <li className="divider" data-text="main">
            Main
          </li>
          <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
          <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
          <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
          <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
          <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
          <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>

          <li className="divider" data-text="Inventory">Inventory</li>
          <li>
            <a href="/inventorydashboard" className="active">
              <i className="fas fa-store icon"></i> Inventory
            </a>
          </li>
          <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
          <li className="divider" data-text="Report" onClick={toggleReport}>
            Report
            <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isReportOpen && (
            <>
              <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
              <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
              <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
              <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
              <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
              <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
              <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
              <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
              <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
              <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
            </>
          )}
        </ul>
        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
          <ul className="breadcrumbs">
            <li><Link to="/inventoryDashboard">Inventory</Link></li>
            <li className="divider">/</li>
            <li><Link to="#" className="active">Dashboard</Link></li>
          </ul>

          <div className="users-page">
            <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
              <h1 style={{ color: "white", margin: 2 }}>Inventory :</h1>
              <input
                className="search-input"
                type="text"
                placeholder="Search by Product Name, Brand, S.No, Vendor, Location..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <br />
            {currentUsers.length === 0 && <p>Product doesn't exist</p>}
            {editUser && (
              <EditStock
                editUser={editUser}
                setEditUser={setEditUser}
                updateInventory={updateInventory}
              />
            )}
            <table>
              <thead>
                <tr>
                  <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                  <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product</th>
                  <th style={{ width: "125px", backgroundColor: "#007bff", color: "white" }}>Brand</th>
                  <th style={{ width: "125px", backgroundColor: "#007bff", color: "white" }}>Vendor</th>
                  <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Location</th>
                  <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Last InWard Date</th>
                  <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Inward Stock</th>
                  <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Outward Stock</th>
                  <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Closing Stock</th>
                  <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                    <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                    <td style={{ width: "350px", border: "1px solid black" }}>{getProductName(user.productNameMasterId)}</td>
                    <td style={{ width: "125px", border: "1px solid black" }}>{user.brand}</td>
                    <td style={{ width: "125px", border: "1px solid black" }}>{getVendorName(user.vendorMasterId)}</td>
                    <td style={{ width: "180px", border: "1px solid black" }}>{getGodownName(user.godownMasterId)}</td>
                    <td style={{ width: "150px", border: "1px solid black" }}>{user.lastInwardDate ? formatDate(user.lastInwardDate) : 'N/A'}</td>
                    <td style={{ width: "130px", border: "1px solid black" }}>{user.inward}</td>
                    <td style={{ width: "140px", border: "1px solid black" }}>{user.outward}</td>
                    <td style={{ width: "130px", border: "1px solid black" }}>{user.quantity}</td>
                    <td style={{ width: "60px", border: "1px solid black" }}><i onClick={() => handleEditUser(user)} className="fas fa-pencil"></i></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
              <button
                className="btn btn-primary mx-2"
                onClick={() => paginate(1)}
                disabled={currentPage === 1}
              >
                First Page
              </button>
              <button
                className="btn btn-primary mx-2"
                onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous Page
              </button>
              <button
                className="btn btn-primary mx-2"
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next Page
              </button>
              <button
                className="btn btn-primary mx-2"
                onClick={() => paginate(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last Page
              </button>
              <span className="mx-2">Page {currentPage} of {totalPages}</span>
            </div>
          </div>
        </main>
      </section>
      {/* NAVBAR */}
    </>
  )
}

export default InventoryDashboard;