import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const AdminMachineDetailReport = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [clients, setClients] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedSupervisor, setSelectedSupervisor] = useState('');

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-detail-companies');
                setCompanies(response.data);
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };
        fetchCompanies();
    }, []);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-detail-clients/${selectedCompany || 'All'}`);
                setClients(response.data);
                setSelectedClient('');
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        };
        if (selectedCompany) {
            fetchClients();
        }
    }, [selectedCompany]);

    useEffect(() => {
        const fetchSupervisors = async () => {
            try {
                const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-detail-machines/${selectedCompany || 'All'}/${selectedClient || 'All'}`);
                setSupervisors(response.data);
                setSelectedSupervisor(''); // Reset selected supervisor
            } catch (error) {
                console.error("Error fetching supervisors:", error);
            }
        };

        // Fetch supervisors only if company selection has been made
        if (selectedCompany || selectedClient) {
            fetchSupervisors();
        }
    }, [selectedCompany, selectedClient]); // Fetch on company or client change

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
        setSelectedClient(''); // Reset client selection
    };

    const handleClientChange = (e) => {
        setSelectedClient(e.target.value);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const handleGenerateReport = async () => {
        try {
            const response = await axios.post('https://jubilant-backend.netlysystems.com/generate-machine-detail-report', {
                company: selectedCompany === '' ? 'All' : selectedCompany,
                client: selectedClient === '' ? 'All' : selectedClient,
                supervisor: selectedSupervisor === '' ? 'All' : selectedSupervisor,
            }, {
                responseType: 'blob',
            });

            // Assuming the server sends a PDF file even when there are no records
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Machine_detail_report.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("There was an error generating the report!", error);
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li>
                                <Link to="/admin-machine-detail-report" className="active">
                                    <i className="fas fa-bus icon"></i> Machine Detail Report
                                </Link>
                            </li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/admin-machine-detail-report">Machine Detail Report</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machine Detail Report :</h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="company">Company:</label>
                            <select
                                id="company"
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Company</option>
                                <option value="All">All</option>
                                {companies.map((company) => (
                                    <option key={company._id} value={company._id}>
                                        {company.ownedby}
                                    </option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="site">Site:</label>
                            <select
                                id="client"
                                value={selectedClient}
                                onChange={handleClientChange}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Site</option>
                                <option value="All">All</option>
                                {clients.map((client) => (
                                    <option key={client._id} value={client.clientMasterId}>
                                        {client.fname}
                                    </option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="supervisor">Supervisor:</label>
                            <select
                                id="supervisor"
                                value={selectedSupervisor}
                                onChange={(e) => setSelectedSupervisor(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Supervisor</option>
                                <option value="All">All</option>
                                {supervisors.map((supervisor, index) => (
                                    <option key={index} value={supervisor.supervisor}>
                                        {supervisor.supervisor}
                                    </option>
                                ))}
                            </select>

                            <button onClick={handleGenerateReport}>Generate Report</button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default AdminMachineDetailReport