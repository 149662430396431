// import React, { useCallback, useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
// import './css/DashboardHome.css';

// const AdminClientHome = () => {
//     const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
//     const [sidebarOpen, setSidebarOpen] = useState(false);
//     const [isReportOpen, setIsReportOpen] = useState(false);
//     const [data, setData] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);

//     const toggleSidebar = () => {
//         setSidebarOpen(!sidebarOpen);
//     };
    
//     const toggleReport = () => {
//         setIsReportOpen(!isReportOpen);
//     };

//     const usersPerPage = 30; // Show only 30 users per page

//     const fetchData = useCallback(() => {
//         fetch("https://jubilant-backend.netlysystems.com/getAllClient", {
//             method: "GET",
//         }).then((res) => res.json())
//             .then((data) => {
//                 setData(data.data);
//             });
//     }, []);

//     useEffect(() => {
//         fetchData();
//     }, [fetchData]);

//     const logOut = () => {
//         window.localStorage.clear();
//         window.location.href = "/";
//     };

//     if (!isLoggedIn) {
//         alert("Token Expired Login!! To see Content")
//         window.location.href = "/";
//         return null;
//     }

//     let filteredData = data;
//     if (searchTerm.trim() !== '') {
//         filteredData = data.filter(user => {
//             const searchTerms = searchTerm.toLowerCase().split(' ');
//             return searchTerms.every(term =>
//                 user.fname.toLowerCase().includes(term) ||
//                 user.email.toLowerCase().includes(term) ||
//                 (user.phone && user.phone.toString().toLowerCase().includes(term)) ||
//                 user.gstno.toLowerCase().includes(term)
//             );
//         });
//     }

//     const indexOfLastUser = currentPage * usersPerPage;
//     const indexOfFirstUser = indexOfLastUser - usersPerPage;
//     let currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

//     return (
//         <>
//             {/* SIDEBAR */}
//             <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
//                 <Link to="/" className="brand">
//                     <i className="fas fa-smile icon"></i>CRM
//                 </Link>
//                 <ul className="side-menu">
//                     <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
//                     <li className="divider" data-text="main">
//                         Main
//                     </li>
//                     <li><Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
//                     <li>
//                         <Link to="/adminClient" className="active">
//                             <i className="fas fa-users icon"></i> Clients
//                         </Link>
//                     </li>
//                     <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
//                     <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
//                     <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
//                     <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
//                     <li className="divider" data-text="Inventory">Inventory</li>
//                     <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
//                     <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
//                     <li className="divider" data-text="Report" onClick={toggleReport}>
//                         Report
//                         <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
//                     </li>
//                     {isReportOpen && (
//                         <>
//                             <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
//                             <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
//                         </>
//                     )}
//                 </ul>
//                 <div className="ads">
//                     <div className="wrapper">
//                         <Link to="#" className="btn-upgrade" onClick={logOut}>
//                             Logout
//                         </Link>
//                         <p>
//                             See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
//                         </p>
//                     </div>
//                 </div>
//             </section>
//             {/* SIDEBAR */}

//             {/* NAVBAR */}
//             <section id="content">
//                 <nav>
//                     <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
//                 </nav>

//                 <main>
//                     <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
//                     <ul className="breadcrumbs">
//                         <li><Link to="/adminClient">Site</Link></li>
//                         <li className="divider">/</li>
//                         <li><Link to="#" className="active">Dashboard</Link></li>
//                     </ul>

//                     <div className="users-page">
//                         <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
//                             <h1 style={{ color: "white", margin: 2 }}>Site :</h1>
//                             <input
//                                 className="search-input"
//                                 type="text"
//                                 placeholder="Search by name, email, phone or gst.no..."
//                                 value={searchTerm}
//                                 onChange={(e) => setSearchTerm(e.target.value)}
//                             />
//                         </div>
//                         {currentUsers.length === 0 && <p>Site doesn't exist</p>}
//                         <table>
//                             <thead>
//                                 <tr>
//                                     <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
//                                     <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
//                                     <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Email</th>
//                                     <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Address</th>
//                                     <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Gst No.</th>
//                                     <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Phone</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {currentUsers.map((user, index) => (
//                                     <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
//                                         <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
//                                         <td style={{ width: "350px", border: "1px solid black" }}>{user.fname}</td>
//                                         <td style={{ width: "350px", border: "1px solid black" }}>{user.email}</td>
//                                         <td style={{ width: "350px", border: "1px solid black" }}>{user.address}</td>
//                                         <td style={{ width: "250px", border: "1px solid black" }}>{user.gstno}</td>
//                                         <td style={{ width: "130px", border: "1px solid black" }}>{user.phone}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                         <div className="pagination">
//                             <button
//                                 onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
//                                 disabled={currentPage === 1}
//                             >
//                                 Previous Page
//                             </button>
//                             <button
//                                 onClick={() => setCurrentPage(currentPage + 1)}
//                                 disabled={indexOfLastUser >= data.length}
//                             >
//                                 Next Page
//                             </button>
//                         </div>
//                     </div>
//                 </main>
//             </section>
//             {/* NAVBAR */}
//         </>
//     )
// }

// export default AdminClientHome;


import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';

const AdminClientHome = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    
    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const usersPerPage = 30; // Show only 30 users per page

    const fetchData = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllClient", {
            method: "GET",
        }).then((res) => res.json())
            .then((data) => {
                setData(data.data);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    let filteredData = data;
    if (searchTerm.trim() !== '') {
        filteredData = data.filter(user => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                user.fname.toLowerCase().includes(term) ||
                user.email.toLowerCase().includes(term) ||
                (user.phone && user.phone.toString().toLowerCase().includes(term)) ||
                user.gstno.toLowerCase().includes(term)
            );
        });
    }

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    let currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li>
                        <Link to="/adminClient" className="active">
                            <i className="fas fa-users icon"></i> Clients
                        </Link>
                    </li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminClient">Site</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Site :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by name, email, phone or gst.no..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {currentUsers.length === 0 && <p>Site doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Email</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Address</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Gst No.</th>
                                    <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.fname}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.email}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.address}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.gstno}</td>
                                        <td style={{ width: "130px", border: "1px solid black" }}>{user.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={indexOfLastUser >= data.length}
                            >
                                Next Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default AdminClientHome;