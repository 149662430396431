import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const AdminRequest = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [requests, setRequests] = useState([]);

    const usersPerPage = 30;

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchRequests = async () => {
        try {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/requests');
            setRequests(response.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
        const intervalId = setInterval(fetchRequests, 100);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        fetchRequests();
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const filteredRequests = requests.filter((request) =>
        (request.supervisorName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.brand?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.sno?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.clientName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.status?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.productName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase())
    );

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    // Sort filtered requests by createdAt in descending order (newest first)
    const sortedRequests = filteredRequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Paginate the sorted requests
    const indexOfLastUser = Math.min(currentPage * usersPerPage, sortedRequests.length);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;
    const currentRequests = sortedRequests.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(sortedRequests.length / usersPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li>
                        <Link to="/adminRequest" className="active">
                            <i className="fas fa-ticket icon"></i> Requests
                        </Link>
                    </li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title">Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminRequest">Requests</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div>
                        <h2>All Supervisors Requests</h2>
                        <div className="users-page">
                            <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                                <h1 style={{ color: "white", margin: 2 }}>Requests :</h1>
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search by Supervisors Name, Site, Product Name, Brand, S.No..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        {filteredRequests.length === 0 && <p>Supervisor doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Requested Date</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Supervisor Name</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Serial No.</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Requested Quantity</th>
                                    <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Sent Quantity</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Remaining Quantity</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRequests.map((request, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={request._id}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstUser + index + 1}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{formatDate(request.createdAt)}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.clientName}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.supervisorName}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.brand}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.sno}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.productName}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.requestedQuantity}</td>
                                        <td style={{ width: "130px", border: "1px solid black" }}>{request.sentQuantity}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.remainingQuantity}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default AdminRequest;