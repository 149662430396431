import React, { useState, useEffect } from 'react';

const AddUserForm = () => {
    const [fname, setFname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [phone, setPhone] = useState("");
    const [userType, setUserType] = useState("");
    const [godowns, setGodowns] = useState([]);
    const [godownId, setGodownId] = useState("");

    useEffect(() => {
        fetch("https://jubilant-backend.netlysystems.com/register-godowns")
            .then(res => res.json())
            .then(data => setGodowns(data));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            fname,
            email,
            password,
            city,
            state,
            phone,
            userType,
        };

        if (userType === 'Storekeeper') {
            userData.godownId = godownId;
        }

        fetch("https://jubilant-backend.netlysystems.com/register", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(userData),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("User Successfully Added");
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add User</h1>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {['Admin', 'Staff', 'Supervisor', 'Storekeeper'].map((type) => (
                    <div key={type} style={{ marginBottom: '0.5rem', width: '45%' }}>
                        <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
                            <input
                                type='radio'
                                name='UserType'
                                value={type}
                                onChange={(e) => setUserType(e.target.value)}
                                required
                            />
                            {type}
                        </label>
                    </div>
                ))}
            </div>
            {userType === 'Storekeeper' && (
                <div style={{ marginBottom: '1rem', width: '100%' }}>
                    <label>Godown</label>
                    <select
                        className="form-control"
                        onChange={(e) => setGodownId(e.target.value)}
                        required
                    >
                        <option value="">Select Godown</option>
                        {godowns.map((godown) => (
                            <option key={godown._id} value={godown._id}>
                                {godown.gname}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Name</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Full name"
                    style={{ width: '100%' }}
                    onChange={(e) => setFname(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Email address</label>
                <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    style={{ width: '100%' }}
                    onChange={(e) => setEmail(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Password</label>
                <input
                    type="password"
                    className="form-control"
                    placeholder="Enter Password"
                    style={{ width: '100%' }}
                    onChange={(e) => setPassword(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>City</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    style={{ width: '100%' }}
                    onChange={(e) => setCity(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>State</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter State"
                    style={{ width: '100%' }}
                    onChange={(e) => setState(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Contact</label>
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    style={{ width: '100%' }}
                    onChange={(e) => setPhone(e.target.value)} required
                />
            </div>
            <div style={{ width: '100%' }}>
                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                    Add User
                </button>
            </div>
        </form>
    );
}

export default AddUserForm;