import React, { useState, useEffect } from 'react';

const UpdateUser = ({ user, updateUser }) => {
    const [fname, setFname] = useState(user.fname);
    const [email, setEmail] = useState(user.email);
    const [city, setCity] = useState(user.city);
    const [state, setState] = useState(user.state);
    const [phone, setPhone] = useState(user.phone);
    const [userType, setUserType] = useState(user.userType);
    const [status, setStatus] = useState(user.status);
    const [godowns, setGodowns] = useState([]);
    const [godownId, setGodownId] = useState(user.godownId || "");

    useEffect(() => {
        fetch("https://jubilant-backend.netlysystems.com/register-godowns")
            .then(res => res.json())
            .then(data => setGodowns(data));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedUserData = { fname, email, city, state, phone, userType, status };
        if (userType === 'Storekeeper') {
            updatedUserData.godownId = godownId;
        }

        try {
            const response = await fetch("https://jubilant-backend.netlysystems.com/updateUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ userId: user._id, ...updatedUserData }),
            });
            const result = await response.json();

            if (response.ok) {
                alert("User updated successfully");
                window.location.reload();
            } else {
                alert(result.message || "Failed to update user");
            }
        } catch (error) {
            console.error('Error updating user:', error);
            alert("An error occurred while updating the user");
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit User</h2>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {['Admin', 'Staff', 'Supervisor', 'Storekeeper'].map((type) => (
                    <div key={type} style={{ marginBottom: '0.5rem', width: '45%' }}>
                        <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
                            <input
                                type='radio'
                                name='UserType'
                                value={type}
                                checked={userType === type}
                                onChange={(e) => setUserType(e.target.value)}
                                required
                            />
                            {type}
                        </label>
                    </div>
                ))}
            </div>
            {userType === 'Storekeeper' && (
                <div style={{ marginBottom: '1rem', width: '100%' }}>
                    <label>Godown</label>
                    <select
                        className="form-control"
                        value={godownId}
                        onChange={(e) => setGodownId(e.target.value)}
                        required
                    >
                        <option value="">Select Godown</option>
                        {godowns.map((godown) => (
                            <option key={godown._id} value={godown._id}>
                                {godown.gname}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Name</label>
                <input
                    type="text"
                    className="form-control"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Email address</label>
                <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>City</label>
                <input
                    type="text"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>State</label>
                <input
                    type="text"
                    className="form-control"
                    value={state}
                    onChange={(e) => setState(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Phone</label>
                <input
                    type="number"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)} required
                />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Status</label>
                <select
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)} required
                >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
            </div>
            <div style={{ width: '100%' }}>
                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                    Update User
                </button>
            </div>
        </form>
    );
};

export default UpdateUser;