import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/MachineForm.css';

const TransferMachineForm = () => {
    const [machines, setMachines] = useState([]);
    const [clients, setClients] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [oldClient, setOldClient] = useState('');
    const [newClient, setNewClient] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [ewaybill, setEwayBill] = useState('');
    const [date, setDate] = useState('');
    const [challanNo, setChallanNo] = useState('');
    const [machineTypeMasterId, setTypeMasterId] = useState("");
    const [ownedbyMasterId, setOwnedbyasterId] = useState("");
    const [machineTypes, setMachineTypes] = useState([]);
    const [ownedbys, setOwnedbys] = useState([]);
    const [machineWeightMasterId, setWeightMasterId] = useState("");
    const [machineWeights, setMachineWeights] = useState([]);
    const [type, setType] = useState('');
    const [ownedby, setOwnedby] = useState('');
    const [weight, setWeight] = useState('');
    const [machineCategoryMasterId, setCategoryMasterId] = useState("");
    const [machineCategories, setMachineCategories] = useState([]);
    const [batteryMasterId, setBatteryMasterId] = useState("");
    const [machineBatteries, setMachineBatteries] = useState([]);
    const [category, setCategory] = useState('');
    const [battery, setBattery] = useState('');
    const [transporterName, setTransporterName] = useState('');
    const [oldState, setOldState] = useState('');
    const [states, setStates] = useState([]);
    const [stateMasterId, setStateMasterId] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        axios.get('https://jubilant-backend.netlysystems.com/machines').then(response => setMachines(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/fetchclients').then(response => setClients(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/states').then(response => setStates(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/batteries').then(response => setMachineBatteries(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/supervisors').then(response => setSupervisors(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/machineTypes').then(response => setMachineTypes(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/loan-companies').then(response => setOwnedbys(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/machineWeight').then(response => setMachineWeights(response.data));
        axios.get('https://jubilant-backend.netlysystems.com/machineCategory').then(response => setMachineCategories(response.data));
    }, []);

    const handleMachineSelect = (e) => {
        const machineId = e.target.value;
        setSelectedMachine(machineId);
        const selectedMachine = machines.find(machine => machine._id === machineId);

        if (selectedMachine) {
            setOldClient(selectedMachine.clientName);
            setOldState(selectedMachine.state);
            setTypeMasterId(selectedMachine.machineTypeMasterId);
            const selectedType = machineTypes.find(type => type._id === selectedMachine.machineTypeMasterId);
            setType(selectedType ? selectedType.type : '');

            setOwnedbyasterId(selectedMachine.ownedbyMasterId);
            const selectedOwnedby= ownedbys.find(ownedby => ownedby._id === selectedMachine.ownedbyMasterId);
            setOwnedby(selectedOwnedby ? selectedOwnedby.ownedby : '');

            setWeightMasterId(selectedMachine.machineWeightMasterId);
            const selectedWeight = machineWeights.find(weight => weight._id === selectedMachine.machineWeightMasterId);
            setWeight(selectedWeight ? selectedWeight.weight : '');

            setCategoryMasterId(selectedMachine.machineCategoryMasterId);
            const selectedCategory = machineCategories.find(category => category._id === selectedMachine.machineCategoryMasterId);
            setCategory(selectedCategory ? selectedCategory.category : '');

            setBatteryMasterId(selectedMachine.batteryMasterId);
            const selectedBattery = machineBatteries.find(battery => battery._id === selectedMachine.batteryMasterId);
            setBattery(selectedBattery ? selectedBattery.battery : '');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newClientName = clients.find(client => client._id === newClient)?.fname;
            const response = await axios.post('https://jubilant-backend.netlysystems.com/transferMachine', {
                selectedMachine,
                oldClient,
                newClient,
                newClientName,
                supervisor,
                date,
                challanNo,
                machineWeightMasterId,
                weight,
                machineTypeMasterId,
                type,
                ownedbyMasterId,
                ownedby,
                machineCategoryMasterId,
                category,
                batteryMasterId,
                battery,
                transporterName,
                ewaybill,
                stateMasterId,
                state,
                oldState,
                sno: machines.find(machine => machine._id === selectedMachine)?.sno
            });
            alert('Machine transferred successfully');
            window.location.reload();
        } catch (error) {
            console.error(error);
            alert('Error transferring machine');
        }
    };

    return (
        <form style={{ margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }} onSubmit={handleSubmit} className="machine-form">
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Transfer Machine</h1>

            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Machine Serial Number</label>
                <select className='form-select' style={{ width: '100%' }} value={selectedMachine} onChange={handleMachineSelect}>
                    <option value="">Select Machine</option>
                    {machines.map((machine) => (
                        <option key={machine._id} value={machine._id}>{machine.sno}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Owned By</label>
                <select disabled className="form-select" style={{ width: '100%' }} value={ownedbyMasterId} onChange={(e) => {
                    setOwnedbyasterId(e.target.value);
                    const selectedOwnedby = ownedbys.find(t => t._id === e.target.value);
                    setOwnedby(selectedOwnedby ? selectedOwnedby.ownedby : '');
                }} required>
                    <option value="">Selected Owned By</option>
                    {ownedbys.map((ownedby) => (
                        <option key={ownedby._id} value={ownedby._id}>{ownedby.ownedby}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Category of Machine</label>
                <select disabled className="form-select" style={{ width: '100%' }} value={machineTypeMasterId} onChange={(e) => {
                    setTypeMasterId(e.target.value);
                    const selectedType = machineTypes.find(t => t._id === e.target.value);
                    setType(selectedType ? selectedType.type : '');
                }} required>
                    <option value="">Selected Machine Category</option>
                    {machineTypes.map((type) => (
                        <option key={type._id} value={type._id}>{type.type}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Type of Machine</label>
                <select disabled className="form-select" style={{ width: '100%' }} value={machineCategoryMasterId} onChange={(e) => {
                    setCategoryMasterId(e.target.value);
                    const selectedCategory = machineCategories.find(c => c._id === e.target.value);
                    setCategory(selectedCategory ? selectedCategory.category : '');
                }} required>
                    <option value="">Selected Machine Type</option>
                    {machineCategories.map((category) => (
                        <option key={category._id} value={category._id}>{category.category}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Battery Type</label>
                <select disabled className="form-select" style={{ width: '100%' }} value={batteryMasterId} onChange={(e) => {
                    setBatteryMasterId(e.target.value);
                    const selectedBattery = machineBatteries.find(c => c._id === e.target.value);
                    setBattery(selectedBattery ? selectedBattery.battery : '');
                }} required>
                    <option value="">Selected Battery Type</option>
                    {machineBatteries.map((battery) => (
                        <option key={battery._id} value={battery._id}>{battery.battery}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Machine Capacity <span style={{ color: "red", fontWeight: "bold" }}>(TON)*</span></label>
                <select disabled className="form-select" style={{ width: '100%' }} value={machineWeightMasterId} onChange={(e) => {
                    setWeightMasterId(e.target.value);
                    const selectedWeight = machineWeights.find(t => t._id === e.target.value);
                    setWeight(selectedWeight ? selectedWeight.weight : '');
                }} required>
                    <option value="">Selected Machine Capacity</option>
                    {machineWeights.map((weight) => (
                        <option key={weight._id} value={weight._id}>{weight.weight}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Dispatched From</label>
                <input className='form-control' style={{ width: '100%' }} type="text" value={oldClient} readOnly />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Dispatched To</label>
                <select className='form-select' style={{ width: '100%' }} value={newClient} onChange={(e) => setNewClient(e.target.value)}>
                    <option value="">Select Site</option>
                    {clients.map((client) => (
                        <option key={client._id} value={client._id}>{client.fname}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Old State</label>
                <input className='form-control' style={{ width: '100%' }} type="text" value={oldState} readOnly />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>New State</label>
                <select className='form-select' style={{ width: '100%' }} value={stateMasterId} onChange={(e) => {
                    setStateMasterId(e.target.value);
                    const selectedState = states.find(state => state._id === e.target.value);
                    setState(selectedState ? selectedState.state : '');
                }}>
                    <option value="">Select State</option>
                    {states.map((state) => (
                        <option key={state._id} value={state._id}>{state.state}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>New Supervisor</label>
                <select className='form-select' style={{ width: '100%' }} value={supervisor} onChange={(e) => setSupervisor(e.target.value)}>
                    <option value="">Select Supervisor</option>
                    {supervisors.map((supervisor) => (
                        <option key={supervisor._id} value={supervisor.fname}>{supervisor.fname}</option>
                    ))}
                </select>
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>E-Way Bill</label>
                <input className='form-control' style={{ width: '100%' }} type="text" value={ewaybill} onChange={(e) => setEwayBill(e.target.value)} />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Date</label>
                <input className='form-control' style={{ width: '100%' }} type="date" value={date} onChange={(e) => setDate(e.target.value)} />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Challan No</label>
                <input className='form-control' style={{ width: '100%' }} type="text" value={challanNo} onChange={(e) => setChallanNo(e.target.value)} />
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Transporter Name</label>
                <input className='form-control' style={{ width: '100%' }} type="text" value={transporterName} onChange={(e) => setTransporterName(e.target.value)} />
            </div>
            <div style={{ width: '100%' }}>
                <button className="btn btn-primary" style={{ width: '100%' }} type="submit">Transfer Machine</button>
            </div>
        </form>
    );
};

export default TransferMachineForm;