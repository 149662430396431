import React, { useState } from 'react';

const SignUp = () => {
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let validKey = false;
    let errorMessage = "";

    switch (userType) {
      case "Admin":
        validKey = secretKey === "IF*3ZC'}_b/id![Gkt`zzd8jOmHe^AdminKey";
        errorMessage = "Invalid Admin";
        break;
      case "Staff":
        validKey = secretKey === "HCtQXZR~-vo'wq3`9}-?x|6ztOi^StaffKey";
        errorMessage = "Invalid Staff";
        break;
      case "Supervisor":
        validKey = secretKey === "*{%Ti&f(bPY0F!f>~.k|39DtFO8<%ASupervisorKey";
        errorMessage = "Invalid Supervisor";
        break;
      case "Storekeeper":
        validKey = secretKey === "]V&-p5BOGU!'*Ok{=e:g9DX))[q<^RStorekeeperKey";
        errorMessage = "Invalid Storekeeper";
        break;
      default:
        validKey = false;
    }

    if (!validKey) {
      alert(errorMessage);
      return;
    }

      fetch("https://jubilant-backend.netlysystems.com/register", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname,
          email,
          password,
          city,
          state,
          phone,
          userType,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            alert(data.error);
          } else {
            alert("User Successfully Added")
            window.location.href = "/"
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }

  return (
    <div className='bg-image'>
      <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }}>
        <div className='card-header bg-primary text-white text-center mb-3'>Sign Up</div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <div style={{ marginBottom: '0.5rem', width: '45%' }}>
            <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
              <input
                type='radio'
                name='UserType'
                value="Admin"
                onChange={(e) => setUserType(e.target.value)} required
              />
              Admin
            </label>
          </div>

          <div style={{ marginBottom: '0.5rem', width: '45%' }}>
            <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
              <input
                type='radio'
                name='UserType'
                value="Staff"
                onChange={(e) => setUserType(e.target.value)} required
              />
              Staff
            </label>
          </div>

          <div style={{ marginBottom: '0.5rem', width: '45%' }}>
            <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
              <input
                type='radio'
                name='UserType'
                value="Supervisor"
                onChange={(e) => setUserType(e.target.value)} required
              />
              Supervisor
            </label>
          </div>

          <div style={{ marginBottom: '0.5rem', width: '45%' }}>
            <label style={{ position: 'relative', cursor: 'pointer', paddingLeft: '1rem' }}>
              <input
                type='radio'
                name='UserType'
                value="Storekeeper"
                onChange={(e) => setUserType(e.target.value)} required
              />
              Storekeeper
            </label>
          </div>
        </div>
        <div id='recaptcha-container'></div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>Secret Key</label>
          <input
            type="text"
            className="form-control"
            placeholder="Secret Key"
            style={{ width: '100%' }}
            onChange={(e) => setSecretKey(e.target.value)} required
          />
        </div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Full name"
            style={{ width: '100%' }}
            onChange={(e) => setFname(e.target.value)} required
          />
        </div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email"
            style={{ width: '100%' }}
            onChange={(e) => setEmail(e.target.value)} required
          />
        </div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter Password"
            style={{ width: '100%' }}
            onChange={(e) => setPassword(e.target.value)} required
          />
        </div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>City</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter City"
            style={{ width: '100%' }}
            onChange={(e) => setCity(e.target.value)} required
          />
        </div>

        <div style={{ marginBottom: '1rem', width: '100%' }}>
          <label>State</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter State"
            style={{ width: '100%' }}
            onChange={(e) => setState(e.target.value)} required
          />
        </div>
        
        <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Contact</label>
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    style={{ width: '100%' }}
                    onChange={(e) => setPhone(e.target.value)} required
                />
            </div>
        <div style={{ width: '100%' }}>
          <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignUp;