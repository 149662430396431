import React, { useState } from 'react';

const EditStock = ({ editUser, setEditUser, updateInventory }) => {
    const [inward, setInward] = useState(editUser.inward);
    const [quantity, setQuantity] = useState(editUser.quantity);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send updated inventory data to backend
        const updatedInventory = {
            inward,
            quantity,
            productNameMasterId: editUser.productNameMasterId,
            godownMasterId: editUser.godownMasterId,
            machineBrandMasterId: editUser.machineBrandMasterId,
            vendorMasterId: editUser.vendorMasterId,
        };
        updateInventory(updatedInventory);
        setEditUser(null); // Close the form after submitting
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: 'white' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Inventory</h2>

            {/* Display the selected product name */}
            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Product Name:</label>
                <input
                    type="text"
                    className="form-control"
                    value={editUser.productName} // Assuming productName is passed in editUser object
                    readOnly
                />
            </div>

            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Brand:</label>
                <input
                    type="text"
                    className="form-control"
                    value={editUser.brand} // Assuming productName is passed in editUser object
                    readOnly
                />
            </div>

            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Inward Stock:</label>
                <input
                    type="number"
                    className="form-control"
                    value={inward}
                    onChange={(e) => setInward(e.target.value)} required
                />
            </div>

            <div style={{ marginBottom: '1rem', width: '100%' }}>
                <label>Quantity:</label>
                <input
                    type="number"
                    className="form-control"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                />
            </div>

            <div style={{ width: '100%' }}>
                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                    Update Inventory
                </button>
            </div>
        </form>
    );
};

export default EditStock;