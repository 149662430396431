import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';

const InventoryReport = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(true);
    const [godowns, setGodowns] = useState([]);
    const [brands, setBrands] = useState([]);
    const [godown, setGodown] = useState('');
    const [brand, setBrand] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        fetch('https://jubilant-backend.netlysystems.com/get-godowns')
            .then(response => response.json())
            .then(data => setGodowns(data))
            .catch(error => console.error('Error fetching godowns:', error));

        fetch('https://jubilant-backend.netlysystems.com/get-brands')
            .then(response => response.json())
            .then(data => setBrands(data))
            .catch(error => console.error('Error fetching brands:', error));
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/sign-in";
        return null;
    }

    const handleGenerateReport = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('https://jubilant-backend.netlysystems.com/generate-inventory-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ godown, brand, startDate, endDate }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `inventory_report_${startDate}_to_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("There was an error generating the report!", error);
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>

                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li>
                                <a href="/inventory-report" className="active">
                                    <i className="fas fa-store icon"></i> Inventory Report
                                </a>
                            </li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/inventory-report">Inventory Report</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Inventory Report :</h1>
                        </div>
                        <form onSubmit={handleGenerateReport}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Godown:</label>
                                <select
                                    value={godown}
                                    onChange={(e) => setGodown(e.target.value)}
                                    required
                                    style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                                >
                                    <option value="">Select a godown</option>
                                    <option value="all">All</option>
                                    {godowns.map((godown) => (
                                        <option key={godown._id} value={godown._id}>
                                            {godown.gname}
                                        </option>
                                    ))}
                                </select>

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Brand:</label>
                                <select
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                                >
                                    <option value="">Select a brand</option>
                                    <option value="all">All</option>
                                    {brands.map((brand, index) => (
                                        <option key={index} value={brand}>
                                            {brand}
                                        </option>
                                    ))}
                                </select>

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>Start Date:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                                />

                                <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }}>End Date:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                                />
                                <button type="submit">Generate Report</button>
                            </div>
                        </form>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default InventoryReport;