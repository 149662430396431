import React, { useEffect, useState } from "react";
import "./css/DashboardHome.css";
import image from './css/forklift.jpg';
import { Link } from "react-router-dom";
export default function SupervisorHome({ userData }) {
  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [totalClients, setTotalClients] = useState(0);
  const [totalMachines, setTotalMachines] = useState(0);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/"
  };

  useEffect(() => {
    const fetchSupervisorDetails = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch('https://jubilant-backend.netlysystems.com/supervisor-client-machine', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setTotalClients(data.totalClients);
      setTotalMachines(data.totalMachines);
    };

    fetchSupervisorDetails();
  }, []);

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content")
    window.location.href = "/";
    return null;
  }

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <Link to="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </Link>
        <ul className="side-menu">
          <li>
            <Link to="/" className="active">
              <i className="fas fa-dashboard icon"></i> Dashboard
            </Link>
          </li>
          <li className="divider" data-text="main">
            Main
          </li>
          <li><Link to="/supervisorMachine"><i className="fas fa-server icon"></i>Machines</Link></li>
          <li><Link to="/supervisorRequest"><i className="fas fa-ticket icon"></i>Requests</Link></li>
          <li><Link to="/supervisorInspection"><i className="fas fa-street-view icon"></i>Inspection</Link></li>
          <li><Link to="/supervisorDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
          <li><Link to="/supervisorInventory"><i className="fas fa-store icon"></i>Supervisor Inventory</Link></li>
        </ul>
        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> {userData.fname} Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title">Hello Supervisor {userData.fname},</h4>
          <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li className="divider">/</li>
            <li><Link to="#" className="active">Dashboard</Link></li>
          </ul>
          <ul className="box-info">
            <li>
              <i className="fas fa-users"></i>
              <span className="text">
                <p>Total Clients:</p>
                <h3>{totalClients}</h3>
              </span>
            </li>
            <li>
              <i className="fas fa-server"></i>
              <span className="text">
                <p>Total Machines:</p>
                <h3>{totalMachines}</h3>
              </span>
            </li>
          </ul>
          <div className="image-container">
            <img className="forklift" src={image} alt="Forklift" />
          </div>
        </main>
      </section>
      {/* NAVBAR */}
    </>
  );
}