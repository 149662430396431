import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SupervisorMachine = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [machines, setMachines] = useState([]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    useEffect(() => {
        const fetchSupervisorDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://jubilant-backend.netlysystems.com/supervisor-clients-machines', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                const { clients, machines } = data;
                setClients(clients);
                setMachines(machines);
            } catch (error) {
                console.error('Error fetching supervisor details:', error);
            }
        };

        fetchSupervisorDetails();
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/";
        return null;
    }

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">Main</li>
                    <li>
                        <Link to="/supervisorMachine" className="active">
                            <i className="fas fa-ticket icon"></i> Machines
                        </Link>
                    </li>
                    <li><Link to="/supervisorRequest"><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to="/supervisorInspection"><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to="/supervisorDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li><Link to="/supervisorInventory"><i className="fas fa-store icon"></i>Supervisor Inventory</Link></li>
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Supervisor</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisorMachine">Machine & Site</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                        <h1 style={{ color: "white", margin: 2 }}>Machine & Site :</h1>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Serial No.</th>
                                <th style={{ width: "210px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity <span style={{color:"black"}}>(TON)*</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {machines.map((machine, index) => (
                                <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                    <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                    <td style={{ width: "200px", border: "1px solid black" }}>{machine.state}</td>
                                    <td style={{ width: "350px", border: "1px solid black" }}>{machine.clientName}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{machine.brand}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{machine.sno}</td>
                                    <td style={{ width: "210px", border: "1px solid black" }}>{machine.weight}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default SupervisorMachine;