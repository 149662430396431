import React, { useEffect, useState } from 'react';
import './css/MachineForm.css';
import axios from 'axios';

const AddMachineForm = () => {
  const [clientMasterId, setClientMasterId] = useState("");
  const [brandMasters, setBrandMasters] = useState([]);
  const [machineBrandMasterId, setBrandMasterId] = useState("");
  const [machineWeightMasterId, setWeightMasterId] = useState("");
  const [typeMasters, setTypeMasters] = useState([]);
  const [stateMasters, setStateMasters] = useState([]);
  const [ownedbyMasters, setOwnedbyMasters] = useState([]);
  const [machineTypeMasterId, setTypeMasterId] = useState("");
  const [stateMasterId, setStateMasterId] = useState("");
  const [ownedbyMasterId, setOwnedbyMasterId] = useState("");
  const [categoryMasters, setCategoryMasters] = useState([]);
  const [machineCategoryMasterId, setCategoryMasterId] = useState("");
  const [batteryMasterId, setBatteryMasterId] = useState("");
  const [brand, setBrand] = useState("");
  const [weight, setWeight] = useState("");
  const [type, setType] = useState("");
  const [state, setState] = useState("");
  const [ownedby, setOwnedby] = useState("");
  const [category, setCategory] = useState("");
  const [battery, setBattery] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [sno, setSno] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [ewaybill, setEWayBill] = useState("");
  const [yearofpurchase, setYearofPurchase] = useState("");
  const [purchaseInvoice, setPurchaseInvoice] = useState("");
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [loanNo, setLoanNo] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [totalEmi, setTotalEmi] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
  const [emiStart, setEmiStart] = useState("");
  const [emiEnd, setEmiEnd] = useState("");
  const [clientsList, setClientsList] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [machineCategory, setMachineCategory] = useState([]);
  const [machineBattery, setMachineBattery] = useState([]);
  const [machineBrand, setMachineBrand] = useState([]);
  const [machineWeight, setMachineWeight] = useState([]);
  const [ownedbys, setOwnedBys] = useState([]);
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [error, setError] = useState(null);
  const [isBatteryFieldDisabled, setIsBatteryFieldDisabled] = useState(true);

  useEffect(() => {
    fetchClients();
    fetchSupervisors();
    fetchMachineTypes();
    fetchStates();
    fetchBatteries();
    fetchMachineCategory();
    fetchMachineBrand();
    fetchMachineWeight();
    fetchCompany();
  }, []);

  const fetchCompany = () => {
    fetch("https://jubilant-backend.netlysystems.com/companyNames")
      .then((res) => res.json())
      .then((data) => {
        setOwnedBys(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineBrand = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineBrand")
      .then((res) => res.json())
      .then((data) => {
        setMachineBrand(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineWeight = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineWeight")
      .then((res) => res.json())
      .then((data) => {
        setMachineWeight(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineCategory = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineCategory")
      .then((res) => res.json())
      .then((data) => {
        setMachineCategory(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchBatteries = () => {
    fetch("https://jubilant-backend.netlysystems.com/batteries")
      .then((res) => res.json())
      .then((data) => {
        setMachineBattery(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineTypes = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineTypes")
      .then((res) => res.json())
      .then((data) => {
        setMachineTypes(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchStates = () => {
    fetch("https://jubilant-backend.netlysystems.com/states")
      .then((res) => res.json())
      .then((data) => {
        setStates(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchClients = () => {
    fetch("https://jubilant-backend.netlysystems.com/fetchclients")
      .then((res) => res.json())
      .then((data) => {
        setClientsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSupervisors = () => {
    fetch("https://jubilant-backend.netlysystems.com/supervisors")
      .then((res) => res.json())
      .then((data) => {
        setSupervisorsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://jubilant-backend.netlysystems.com/addMachine', {
      machineWeightMasterId,
      weight,
      machineBrandMasterId,
      brand,
      machineTypeMasterId,
      type,
      stateMasterId,
      state,
      machineCategoryMasterId,
      category,
      clientMasterId,
      supervisor,
      sno,
      yearofpurchase,
      ownedbyMasterId,
      ownedby,
      purchaseInvoice,
      purchaseAmount,
      purchaseDate,
      loanNo,
      loanAmount,
      totalEmi,
      emiAmount,
      emiStart,
      emiEnd,
      challanNo,
      ewaybill,
      batteryMasterId: !isBatteryFieldDisabled ? batteryMasterId : undefined,
      battery: !isBatteryFieldDisabled ? machineBattery.find(b => b._id === batteryMasterId)?.battery : undefined,
    })
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          alert("Machine Successfully Added");
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form style={{ margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }} onSubmit={handleSubmit} className="machine-form">
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Machine</h1>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Machine Brand</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setBrandMasterId(e.target.value);
            const selectedBrand = machineBrand.find(b => b._id === e.target.value);
            setBrand(selectedBrand ? selectedBrand.brand : '');
          }} required
        >
          <option value="">Select Machine Brand</option>
          {machineBrand.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.brand}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Machine Capacity <span style={{ color: "red", fontWeight: "bolder" }} >(TON)*</span></label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setWeightMasterId(e.target.value);
            const selectedWeight = machineWeight.find(b => b._id === e.target.value);
            setWeight(selectedWeight ? selectedWeight.weight : '');
          }} required
        >
          <option value="">Select Machine Capacity</option>
          {machineWeight.map((weight) => (
            <option key={weight._id} value={weight._id}>
              {weight.weight}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Category of Machine</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setTypeMasterId(e.target.value);
            const selectedType = machineTypes.find(t => t._id === e.target.value);
            setType(selectedType ? selectedType.type : '');
          }} required
        >
          <option value="">Select Machine Category</option>
          {machineTypes.map((type) => (
            <option key={type._id} value={type._id}>
              {type.type}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Type of Machine</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setCategoryMasterId(e.target.value);
            const selectedCategory = machineCategory.find(c => c._id === e.target.value);
            setCategory(selectedCategory ? selectedCategory.category : '');
            setIsBatteryFieldDisabled(selectedCategory && selectedCategory.category.toLowerCase() !== 'battery');
          }} required
        >
          <option value="">Select Machine Type</option>
          {machineCategory.map((category) => (
            <option key={category._id} value={category._id}>
              {category.category}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Battery Charger</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setBatteryMasterId(e.target.value);
          }}
          disabled={isBatteryFieldDisabled}
          required={!isBatteryFieldDisabled}
        >
          <option value="">Select Battery Charger</option>
          {machineBattery.map((battery) => (
            <option key={battery._id} value={battery._id}>
              {battery.battery}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Site</label>
        <select
          className="form-control"
          style={{ width: '100%' }}
          onChange={(e) => {
            setClientMasterId(e.target.value);
          }} required
        >
          <option value="">Select Site</option>
          {clientsList.map((client) => (
            <option key={client._id} value={client._id}>
              {client.fname}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>State</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setStateMasterId(e.target.value);
            const selectedState = states.find(t => t._id === e.target.value);
            setState(selectedState ? selectedState.state : '');
          }} required
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state._id} value={state._id}>
              {state.state}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Supervisor</label>
        <select
          className="form-control"
          style={{ width: '100%' }}
          onChange={(e) => setSupervisor(e.target.value)} required
        >
          <option value="">Select Supervisor</option>
          {supervisorsList.map((supervisor) => (
            <option key={supervisor._id} value={supervisor.fname}>
              {supervisor.fname}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Serial.No</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Serial No"
          style={{ width: '100%' }}
          onChange={(e) => setSno(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Challan No</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Challan No"
          style={{ width: '100%' }}
          onChange={(e) => setChallanNo(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>E-Way Bill</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter E-Way Bill"
          style={{ width: '100%' }}
          onChange={(e) => setEWayBill(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Year Of Purchase:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Year Of Purchase"
          style={{ width: '100%' }}
          onChange={(e) => setYearofPurchase(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Owned By</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          onChange={(e) => {
            setOwnedbyMasterId(e.target.value);
            const selectedownedby = ownedbys.find(t => t._id === e.target.value);
            setOwnedby(selectedownedby ? selectedownedby.ownedby : '');
          }} required
        >
          <option value="">Select OwnedBy</option>
          {ownedbys.map((ownedby) => (
            <option key={ownedby._id} value={ownedby._id}>
              {ownedby.ownedby}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Invoice:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Purchase Invoice"
          style={{ width: '100%' }}
          onChange={(e) => setPurchaseInvoice(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Purchase Amount"
          style={{ width: '100%' }}
          onChange={(e) => setPurchaseAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Date:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          onChange={(e) => setPurchaseDate(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Loan.No:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Purchase Invoice"
          style={{ width: '100%' }}
          onChange={(e) => setLoanNo(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Loan Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Loan Amount"
          style={{ width: '100%' }}
          onChange={(e) => setLoanAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Total No. of Emi:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Total Emi"
          style={{ width: '100%' }}
          onChange={(e) => setTotalEmi(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Emi Amount"
          style={{ width: '100%' }}
          onChange={(e) => setEmiAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi Start:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          onChange={(e) => setEmiStart(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi End:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          onChange={(e) => setEmiEnd(e.target.value)} required
        />
      </div>

      <div style={{ width: '100%' }}>
        <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
          Add Machine
        </button>
      </div>
    </form>
  );
}

export default AddMachineForm;