import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const StorekeeperUsage = () => {
    const token = window.localStorage.getItem("token");
    const [quantity, setQuantity] = useState('');
    const [productList, setProductList] = useState([]);
    const [filteredProductList, setFilteredProductList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [machineBrandMasterId, setMachineBrandMasterId] = useState('');
    const [currentStock, setCurrentStock] = useState('');
    const [machineList, setMachineList] = useState([]);
    const [filteredMachineList, setFilteredMachineList] = useState([]);
    const [machineSearchTerm, setMachineSearchTerm] = useState('');
    const [selectedMachineSno, setSelectedMachineSno] = useState(null);
    const [showProductDropdown, setShowProductDropdown] = useState(false);
    const [showMachineDropdown, setShowMachineDropdown] = useState(false);
    const [brands, setBrands] = useState([]);
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [brandSearchTerm, setBrandSearchTerm] = useState('');
    const productDropdownRef = useRef(null);
    const machineDropdownRef = useRef(null);
    const brandDropdownRef = useRef(null);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/storekeeper-brands', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, [token]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/storekeeper-usequantity-productNames', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProductList(response.data);
                setFilteredProductList(response.data.filter(product => product.brand === selectedBrand));
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [token, selectedBrand]);

    useEffect(() => {
        const fetchMachines = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/storekeeper-usequantity-machines', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMachineList(response.data);
                setFilteredMachineList(response.data);
            } catch (error) {
                console.error('Error fetching machines:', error);
            }
        };

        fetchMachines();
    }, [token]);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredProductList(productList.filter(product => product.brand === selectedBrand));
        } else {
            setFilteredProductList(productList.filter(product =>
                product.productName.toLowerCase().includes(searchTerm.toLowerCase()) &&
                product.brand === selectedBrand
            ));
        }
    }, [searchTerm, productList, selectedBrand]);

    useEffect(() => {
        if (machineSearchTerm === '') {
            setFilteredMachineList(machineList);
        } else {
            setFilteredMachineList(machineList.filter(machine =>
                machine.sno && // Check if sno exists
                machine.sno.toLowerCase().includes(machineSearchTerm.toLowerCase())
            ));
        }
    }, [machineSearchTerm, machineList]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (productDropdownRef.current && !productDropdownRef.current.contains(event.target)) {
                setShowProductDropdown(false);
            }
            if (machineDropdownRef.current && !machineDropdownRef.current.contains(event.target)) {
                setShowMachineDropdown(false);
            }
            if (brandDropdownRef.current && !brandDropdownRef.current.contains(event.target)) {
                setShowBrandDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleProductChange = (product) => {
        setSelectedProduct(product._id);
        setSelectedBrand(product.brand);
        setMachineBrandMasterId(product.machineBrandMasterId);
        setCurrentStock(product.quantity);
        setSearchTerm(product.productName);
        setShowProductDropdown(false);
    };

    const handleMachineChange = (machine) => {
        setSelectedMachineSno(machine.sno); // Update serial number
        setMachineSearchTerm(machine.sno);
        setShowMachineDropdown(false);
    };

    const handleBrandChange = (brand) => {
        setSelectedBrand(brand.brand);
        setBrandSearchTerm(brand.brand);
        setShowBrandDropdown(false);
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        if (value < 0) {
            alert('You cannot enter a negative value');
            return;
        }
        setQuantity(value);
    };

    const handleUseQuantity = async (e) => {
        e.preventDefault();

        if (!selectedProduct || !quantity || !selectedMachineSno) {
            alert('Please select a product, machine, and enter a quantity.');
            return;
        }

        try {
            await axios.post('https://jubilant-backend.netlysystems.com/useQuantity', {
                productId: selectedProduct,
                quantity: Number(quantity),
                machineBrandMasterId: machineBrandMasterId,
                brand: selectedBrand,
                sno: selectedMachineSno
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert('Quantity used successfully!');
            window.location.reload();
        } catch (error) {
            console.error(error);
            alert('Failed to use quantity. Please try again.');
        }
    };

    const handleProductInputClick = () => {
        setShowProductDropdown(true);
    };

    const handleMachineInputClick = () => {
        setShowMachineDropdown(true);
    };

    const handleBrandInputClick = () => {
        setShowBrandDropdown(true);
    };

    return (
        <div>
            <form
                style={{
                    margin: '0 auto',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: "white",
                    maxWidth: '800px',
                }}
                onSubmit={handleUseQuantity}
                className="add-quantity-form"
            >
                <h1
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        color: '#333'
                    }}
                >
                    Use Stock
                </h1>

                <div ref={brandDropdownRef} style={{ position: 'relative' }}>
                    <label htmlFor="brand">Select Brand:</label>
                    <input
                        id="brand"
                        type="text"
                        placeholder="Select Brand"
                        value={brandSearchTerm}
                        onChange={(e) => setBrandSearchTerm(e.target.value)}
                        onClick={handleBrandInputClick}
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                    />
                    {showBrandDropdown && brands.length > 0 && (
                        <div style={{ border: "1px solid #ccc", listStyleType: "none", padding: "0", marginTop: "0", maxHeight: '150px', overflowY: 'auto', position: 'absolute', zIndex: 1, backgroundColor: 'white', width: '100%' }}>
                            {brands.filter(brand => brand.brand.toLowerCase().includes(brandSearchTerm.toLowerCase())).map(brand => (
                                <div
                                    key={brand._id}
                                    onClick={() => handleBrandChange(brand)}
                                    style={{ display: 'block', marginBottom: '15px' }}
                                >
                                    {brand.brand}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div ref={productDropdownRef} style={{ position: 'relative', marginTop: '10px' }}>
                    <label htmlFor="product">Search Product:</label>
                    <input
                        id="product"
                        type="text"
                        placeholder="Search Product"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={handleProductInputClick}
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                    />
                    {showProductDropdown && filteredProductList.length > 0 && (
                        <div style={{ border: "1px solid #ccc", listStyleType: "none", padding: "0", marginTop: "0", maxHeight: '150px', overflowY: 'auto', position: 'absolute', zIndex: 1, backgroundColor: 'white', width: '100%' }}>
                            {filteredProductList.map(product => (
                                <div
                                    key={product._id}
                                    onClick={() => handleProductChange(product)}
                                    style={{ display: 'block', marginBottom: '15px' }}
                                >
                                    {product.productName}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div ref={machineDropdownRef} style={{ position: 'relative', marginTop: '10px' }}>
                    <label htmlFor="machine">Search Machine Serial Number:</label>
                    <input
                        id="machine"
                        type="text"
                        placeholder="Search Machine"
                        value={machineSearchTerm}
                        onChange={(e) => setMachineSearchTerm(e.target.value)}
                        onClick={handleMachineInputClick}
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                    />
                    {showMachineDropdown && filteredMachineList.length > 0 && (
                        <div style={{ border: "1px solid #ccc", listStyleType: "none", padding: "0", marginTop: "0", maxHeight: '150px', overflowY: 'auto', position: 'absolute', zIndex: 1, backgroundColor: 'white', width: '100%' }}>
                            {filteredMachineList.map(machine => (
                                <div
                                    key={machine._id}
                                    onClick={() => handleMachineChange(machine)}
                                    style={{ display: 'block', marginBottom: '15px' }}
                                >
                                    {machine.sno}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div>
                    <label style={{ display: 'block', marginTop: '10px' }}>
                            Current Stock:
                            <input
                               style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                                type="number"
                                value={currentStock}
                                disabled
                            />
                    </label>
                </div>

                <div style={{ marginTop: '10px'}}>
                    <label style={{ display: 'block' }} htmlFor="quantity">Quantity:</label>
                    <input
                        id="quantity"
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        placeholder="Enter quantity"
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                    />
                </div>

                <button
                    type="submit"
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '3px',
                        backgroundColor: '#007BFF',
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                >
                    Use Quantity
                </button>
            </form>
        </div>
    );
};

export default StorekeeperUsage;