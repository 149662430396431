import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const MachineDetailReport = () => {
  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMasterOpen, setIsMasterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [clients, setClients] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedSupervisor, setSelectedSupervisor] = useState('');

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-detail-companies');
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-detail-clients/${selectedCompany || 'All'}`);
        setClients(response.data);
        setSelectedClient('');
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    if (selectedCompany) {
      fetchClients();
    }
  }, [selectedCompany]);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-detail-machines/${selectedCompany || 'All'}/${selectedClient || 'All'}`);
        setSupervisors(response.data);
        setSelectedSupervisor(''); // Reset selected supervisor
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    };

    // Fetch supervisors only if company selection has been made
    if (selectedCompany || selectedClient) {
      fetchSupervisors();
    }
  }, [selectedCompany, selectedClient]); // Fetch on company or client change

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    setSelectedClient(''); // Reset client selection
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleMaster = () => {
    setIsMasterOpen(!isMasterOpen);
  };

  const toggleReport = () => {
    setIsReportOpen(!isReportOpen);
  };

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/"
  };

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content");
    window.location.href = "/";
    return null;
  }

  const handleGenerateReport = async () => {
    try {
      const response = await axios.post('https://jubilant-backend.netlysystems.com/generate-machine-detail-report', {
        company: selectedCompany === '' ? 'All' : selectedCompany,
        client: selectedClient === '' ? 'All' : selectedClient,
        supervisor: selectedSupervisor === '' ? 'All' : selectedSupervisor,
      }, {
        responseType: 'blob',
      });

      // Assuming the server sends a PDF file even when there are no records
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Machine_detail_report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error("There was an error generating the report!", error);
    }
  };

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <a href="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </a>
        <ul className="side-menu">
          <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
          <li className="divider" data-text="Master" onClick={toggleMaster}>
            Master
            <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isMasterOpen && (
            <>
              <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
              <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
              <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
              <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
              <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
              <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
              <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
              <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
            </>
          )}
          <li className="divider" data-text="main">
            Main
          </li>
          <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
          <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
          <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
          <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
          <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
          <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
          <li className="divider" data-text="Inventory">Inventory</li>
          <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
          <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>

          <li className="divider" data-text="Report" onClick={toggleReport}>
            Report
            <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
          </li>
          {isReportOpen && (
            <>
              <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
              <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report </Link></li>
              <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report </Link></li>
              <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
              <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
              <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
              <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
              <li>
                <a href="/machine-detail-report" className="active">
                  <i className="fas fa-bus icon"></i>Machine Detail Report
                </a>
              </li>
              <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
              <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
            </>
          )}
        </ul>
        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
          <ul className="breadcrumbs">
            <li><Link to="/machine-detail-report">Machine Detail Report</Link></li>
            <li className="divider">/</li>
            <li><Link to="#" className="active">Dashboard</Link></li>
          </ul>

          <div className="users-page">
            <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
              <h1 style={{ color: "white", margin: 2 }}>Machine Detail Report :</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
              <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="company">Company:</label>
              <select
                id="company"
                value={selectedCompany}
                onChange={handleCompanyChange}
                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
              >
                <option value="">Select Company</option>
                <option value="All">All</option>
                {companies.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.ownedby}
                  </option>
                ))}
              </select>

              <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="site">Site:</label>
              <select
                id="client"
                value={selectedClient}
                 onChange={handleClientChange}
                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
              >
                <option value="">Select Site</option>
                <option value="All">All</option>
                {clients.map((client) => (
                  <option key={client._id} value={client.clientMasterId}>
                    {client.fname}
                  </option>
                ))}
              </select>

              <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="supervisor">Supervisor:</label>
              <select
                id="supervisor"
                value={selectedSupervisor}
                onChange={(e) => setSelectedSupervisor(e.target.value)}
                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
              >
                <option value="">Select Supervisor</option>
                <option value="All">All</option>
                {supervisors.map((supervisor, index) => (
                  <option key={index} value={supervisor.supervisor}>
                    {supervisor.supervisor}
                  </option>
                ))}
              </select>

              <button onClick={handleGenerateReport}>Generate Report</button>
            </div>
          </div>
        </main>
      </section >
      {/* NAVBAR */}
    </>
  );
};

export default MachineDetailReport;