import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const StorekeeperRequest = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const token = window.localStorage.getItem("token");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [requests, setRequests] = useState([]);
    const [assignedQuantity, setAssignedQuantity] = useState({});
    const [totalSentQuantity, setTotalSentQuantity] = useState({});

    const usersPerPage = 30; // Show users per page

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const fetchRequests = async () => {
        try {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/requests');
            setRequests(response.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
        const intervalId = setInterval(fetchRequests, 100);
        return () => clearInterval(intervalId);
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const handleAssignQuantityChange = (e, requestId) => {
        const value = parseInt(e.target.value);
        setAssignedQuantity({
            ...assignedQuantity,
            [requestId]: value
        });
    };

    const handleSubmitAssign = async (e, request) => {
        e.preventDefault();
        const sentQty = assignedQuantity[request._id];
        try {
            const response = await axios.put(
                `https://jubilant-backend.netlysystems.com/requests/${request._id}/assign`,
                {
                    sentQuantity: sentQty,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            // console.log('Quantity assigned:', sentQty);
            alert('Quantity assigned:' + sentQty);
            window.location.reload()

            // Update the total sent quantity
            setTotalSentQuantity(prev => ({
                ...prev,
                [request._id]: (prev[request._id] || 0) + sentQty
            }));

            fetchRequests();
        } catch (error) {
            // console.error('Error assigning quantity:', error);

            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message;
                if (errorMessage === 'Inventory item not found for this godown') {
                    alert('Product not found in your Godown.');
                } else {
                    alert('Error assigning quantity: ' + errorMessage);
                }
            } else {
                alert('Error assigning quantity');
            }
        }
    };

    const handleSendLater = async (id) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/requests/${id}/send-later`);
            alert("Request status updated to Partially Fulfilled");
            fetchRequests();
        } catch (error) {
            console.error('Error updating request status:', error);
        }
    };

    const filteredRequests = requests.filter((request) =>
        (request.supervisorName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.brand?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.sno?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.clientName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.status?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.productName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase())
    );

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    // Sort filtered requests by createdAt in descending order (newest first)
    const sortedRequests = filteredRequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Paginate the sorted requests
    const indexOfLastUser = Math.min(currentPage * usersPerPage, sortedRequests.length);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;
    const currentRequests = sortedRequests.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(sortedRequests.length / usersPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <Link to="/storekeeperRequest" className="active">
                            <i className="fas fa-ticket icon"></i> Requests
                        </Link>
                    </li>
                    <li><Link to="/storekeeperInventory"><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to="/storekeeperDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title">Hello Storekeeper,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/storekeeperRequest">Ticket</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div>
                        <h2>All Supervisors Requests</h2>
                        <div className="users-page">
                            <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                                <h1 style={{ color: "white", margin: 2 }}>Requests :</h1>
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search by Supervisors Name, Site, Product Name, Brand, S.No..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        {filteredRequests.length === 0 && <p>Supervisor doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Requested Date</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Supervisor Name</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Serial No.</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Requested Quantity</th>
                                    <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Sent Quantity</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Remaining Quantity</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Assign Quantity</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Assign Partially</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRequests.map((request, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={request._id}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstUser + index + 1}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{formatDate(request.createdAt)}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.clientName}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.supervisorName}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.brand}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.sno}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.productName}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.requestedQuantity}</td>
                                        <td style={{ width: "130px", border: "1px solid black" }}>{request.sentQuantity}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.remainingQuantity}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.status}</td>
                                        <td style={{ width: "200px",  textAlign: 'center' }}>
                                            <form onSubmit={(e) => handleSubmitAssign(e, request)}>
                                                <input
                                                    type="number"
                                                    style={{ margin: 'auto', display: 'block' }}
                                                    value={assignedQuantity[request._id] || ''}
                                                    onChange={(e) => handleAssignQuantityChange(e, request._id)}
                                                    min="1"
                                                    max={request.remainingQuantity}
                                                    required
                                                />
                                                <button className="btn btn-primary" type="submit">Assign Items</button>
                                            </form>
                                        </td>
                                        <td style={{ width: "140px", textAlign: 'center' }}>
                                            {request.remainingQuantity > 0 && (
                                                <button className="btn btn-primary" style={{ margin: 'auto', display: 'block' }} onClick={() => handleSendLater(request._id)}>Partially</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default StorekeeperRequest;