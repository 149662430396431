import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UpdateInventory = ({ item, onUpdate, onCancel }) => {
    const [formData, setFormData] = useState(item);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [machineBrand, setMachineBrand] = useState([]);
    const [gnameList, setGnameList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [searchedProductName, setSearchedProductName] = useState([]);
    const [showProductSuggestions, setShowProductSuggestions] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetchMachineBrand();
        fetchGodown();
        fetchVendor();
        fetchProduct();
    }, []);

    useEffect(() => {
        const results = productList.filter(product =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm, productList]);

    const fetchProduct = () => {
        fetch("https://jubilant-backend.netlysystems.com/productNames")
            .then((res) => res.json())
            .then((data) => {
                setProductList(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchVendor = () => {
        fetch("https://jubilant-backend.netlysystems.com/vendorNames")
            .then((res) => res.json())
            .then((data) => {
                setVendorList(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchGodown = () => {
        fetch("https://jubilant-backend.netlysystems.com/godownNames")
            .then((res) => res.json())
            .then((data) => {
                setGnameList(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchMachineBrand = () => {
        fetch("https://jubilant-backend.netlysystems.com/machineBrand")
            .then((res) => res.json())
            .then((data) => {
                setMachineBrand(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://jubilant-backend.netlysystems.com/updateinventory/${item._id}`, formData);
            onUpdate(formData);
        } catch (error) {
            console.error(error);
        }
    };

    const handleProductSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setFormData({ ...formData, productName: query });

        if (query.trim() === '') {
            setSearchedProductName([]);
            setShowProductSuggestions(false);
        } else {
            setSearchedProductName(
                productList.filter((product) =>
                    product.productName.toLowerCase().includes(query)
                )
            );
            setShowProductSuggestions(true);
        }
    };

    const handleProductSelect = (product) => {
        setFormData({
            ...formData,
            productNameMasterId: product._id,
            productName: product.productName,
        });
        setShowProductSuggestions(false);
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: 'white' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Inventory</h2>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Product Name:
                <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Search Product Name"
                    value={formData.productName}
                    onChange={handleProductSearch}
                    onFocus={() => setShowProductSuggestions(true)}
                    required
                />
                {showProductSuggestions && (
                    <ul style={{ border: "1px solid #ccc", listStyleType: "none", padding: "0", marginTop: "0" }}>
                        {searchedProductName.map((product) => (
                            <li
                                key={product._id}
                                style={{ padding: "8px", cursor: "pointer" }}
                                onClick={() => handleProductSelect(product)}
                            >
                                {product.productName}
                            </li>
                        ))}
                    </ul>
                )}
            </label>
            <label style={{ display: 'block', marginBottom: '5px' }}>Brand:</label>
            <select
                className="form-select"
                value={formData.machineBrandMasterId}
                onChange={(e) => {
                    const selectedBrandId = e.target.value;
                    const selectedBrand = machineBrand.find(b => b._id === selectedBrandId);
                    setFormData({
                        ...formData,
                        machineBrandMasterId: selectedBrandId,
                        brand: selectedBrand ? selectedBrand.brand : ''
                    });
                }}
                required
            >
                <option value="">Select Machine Brand</option>
                {machineBrand.map((brand) => (
                    <option key={brand._id} value={brand._id}>
                        {brand.brand}
                    </option>
                ))}
            </select>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Vendor Name:
                <select
                    className="form-select"
                    value={formData.vendorMasterId}
                    onChange={(e) => setFormData({ ...formData, vendorMasterId: e.target.value })}
                    required
                >
                    <option value="">Select Vendor</option>
                    {vendorList.map((vendormaster) => (
                        <option key={vendormaster._id} value={vendormaster._id}>
                            {vendormaster.vendor}
                        </option>
                    ))}
                </select>
            </label>
            <label style={{ display: 'block', marginBottom: '5px' }}>Date:</label>
            <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '3px', border: '1px solid #ccc' }}
            />
            <label style={{ display: 'block', marginBottom: '5px' }}>Location:</label>
            <select
                className="form-select"
                value={formData.godownMasterId}
                onChange={(e) => setFormData({ ...formData, godownMasterId: e.target.value })}
                required
                disabled
            >
                <option value="">Select Godown</option>
                {gnameList.map((godownmaster) => (
                    <option key={godownmaster._id} value={godownmaster._id}>
                        {godownmaster.gname}
                    </option>
                ))}
            </select>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <button type="submit" style={{ flex: '1', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Update Inventory</button>
                <button type="button" onClick={onCancel} style={{ flex: '1', padding: '10px', backgroundColor: '#6c757d', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer', marginLeft: '10px' }}>Cancel</button>
            </div>
        </form>
    );
};

export default UpdateInventory;