import React, { Component } from "react";

export default class Reset extends Component {
    constructor (props) {
        super (props);
        this.state = {
            email: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const { email } = this.state;
        alert('Email sent successfully')
        fetch("https://jubilant-backend.netlysystems.com/forget-password", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                email,
            }),
        })
        .then((res)=> res.json())
        .then((data)=>{
            alert(data.status);
        });
    }

    render(){
        return(
            <div className="bg-image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f8f9fa' }}>
                <form onSubmit={this.handleSubmit} style={{ maxWidth: '400px', width: '100%', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                    <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#007bff' }}>Forget Password</h3>

                    <div style={{ width: '100%', marginBottom: '15px' }}>
                        <label>Email Address</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            style={{ width: '100%', padding: '10px', fontSize: '1rem', borderRadius: '4px', border: '1px solid #ccc' }}
                            onChange={(e)=> this.setState({ email: e.target.value })}
                        />
                    </div>

                    <div style={{ width: '100%' }}>
                        <button className="btn btn-primary" style={{ width: '100%', padding: '10px', fontSize: '1rem', borderRadius: '4px', backgroundColor: '#007bff', border: 'none', color: '#fff' }}>
                            Submit
                        </button>
                    </div>

                    <p className="forget-password text-center" style={{ marginTop: '20px', fontSize: '0.875rem', color: '#6c757d' }}>
                        <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Sign In</a>
                    </p>

                    <p className="forget-password text-center" style={{ marginTop: '20px', fontSize: '0.875rem', color: '#6c757d' }}>
                        <a href="/sign-up" style={{ color: '#007bff', textDecoration: 'none' }}>Sign up</a>
                    </p>
                </form>
            </div>
        );
    }
}
