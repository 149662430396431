import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const AdminMachineConsolidatedReport = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [types, setTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-consolidated-companies');
                setCompanies(response.data);
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };

        const fetchMachineTypes = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-consolidated-types');
                setTypes(response.data);
            } catch (error) {
                console.error("Error fetching machine types:", error);
            }
        };

        const fetchMachineBrands = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-consolidated-brands');
                setBrands(response.data);
            } catch (error) {
                console.error("Error fetching machine brands:", error);
            }
        };

        const fetchMachineCategories = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-consolidated-categories');
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching machine categories:", error);
            }
        };

        fetchCompanies();
        fetchMachineTypes();
        fetchMachineBrands();
        fetchMachineCategories();
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const handleGenerateReport = async () => {
        try {
            const response = await axios.post('https://jubilant-backend.netlysystems.com/generate-machine-consolidated-report', {
                company: selectedCompany === '' ? 'All' : selectedCompany,
                type: selectedType === '' ? 'All' : selectedType,
                brand: selectedBrand === '' ? 'All' : selectedBrand,
                category: selectedCategory === '' ? 'All' : selectedCategory,
            }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Machine_Consolidated_Report.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("There was an error generating the report!", error);
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li>
                                <Link to="/admin-machine-consolidated-report" className="active">
                                    <i className="fas fa-cogs icon"></i> Machine Consolidated Report
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/admin-machine-consolidated-report">Machine Consolidated Report</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machine Consolidated Report :</h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="company">Company:</label>
                            <select
                                id="company"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Company</option>
                                <option value="All">All</option>
                                {companies.map(company => (
                                    <option key={company._id} value={company._id}>
                                        {company.ownedby}
                                    </option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="type">Type:</label>
                            <select
                                id="type"
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Category</option>
                                <option value="All">All</option>
                                {types.map(type => (
                                    <option key={type._id} value={type._id}>
                                        {type.type}
                                    </option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="brand">Brand:</label>
                            <select
                                id="brand"
                                value={selectedBrand}
                                onChange={(e) => setSelectedBrand(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Brand</option>
                                <option value="All">All</option>
                                {brands.map(brand => (
                                    <option key={brand._id} value={brand._id}>
                                        {brand.brand}
                                    </option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="category">Category:</label>
                            <select
                                id="category"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc' }}
                            >
                                <option value="">Select Type</option>
                                <option value="All">All</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>
                                        {category.category}
                                    </option>
                                ))}
                            </select>

                            <button onClick={handleGenerateReport}>Generate Report</button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default AdminMachineConsolidatedReport