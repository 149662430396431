import React, { useEffect, useState } from "react";
import SuperAdminHome from './SuperAdminHome';
import AdminHome from './AdminHome';
import StaffHome from './StaffHome';
import SupervisorHome from './SupervisorHome';
import StorekeeperHome from './StorekeeperHome';

const UserDetails = () => {
    const [userData, setUserData] = useState(false);
    const [userRole, setUserRole] = useState("");

    useEffect(() => {
        fetch("https://jubilant-backend.netlysystems.com/userData", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                token: window.localStorage.getItem("token"),
            }),
        })
        .then((res) => res.json())
        .then((data) => {
            setUserData(data.data);
            setUserRole(data.data.userType);

            if (data.data === "token expired") {
                alert("Token Expired Login Again");
                window.localStorage.clear();
                window.location.href = "/"
            }
        });
    }, []);

    let HomeComponent = null;

    switch (userRole) {
        case "SuperAdmin":
            HomeComponent = <SuperAdminHome userData={userData} />;
            break;
        case "Admin":
            HomeComponent = <AdminHome userData={userData} />;
            break;
        case "Staff":
            HomeComponent = <StaffHome userData={userData} />;
            break;
        case "Supervisor":
            HomeComponent = <SupervisorHome userData={userData} />;
            break;
        case "Storekeeper":
            HomeComponent = <StorekeeperHome userData={userData} />;
            break;
        default:
            HomeComponent = null;
            break;
    }

    return HomeComponent;
};

export default UserDetails;