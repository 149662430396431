import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

const AddQuantity = () => {
    const [formData, setFormData] = useState({
        selectedBrand: "",
        selectedProduct: "",
        productName: "",
        quantity: "",
    });
    const [brandList, setBrandList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchBrandList();
    }, []);

    useEffect(() => {
        if (formData.selectedBrand) {
            fetchProductList(formData.selectedBrand);
        }
    }, [formData.selectedBrand]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchBrandList = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://jubilant-backend.netlysystems.com/addQuantity-brands', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setBrandList(response.data);
        } catch (error) {
            console.error('Error fetching brand list:', error);
        }
    };

    const fetchProductList = async (brandId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://jubilant-backend.netlysystems.com/inventory/productNames?brandId=${brandId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProductList(response.data);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const handleBrandChange = (e) => {
        const selectedBrandId = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            selectedBrand: selectedBrandId,
            productName: '',
        }));
        setShowDropdown(false);
    };

    const handleProductSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setFormData((prevData) => ({
            ...prevData,
            productName: query
        }));

        if (query.trim() === "") {
            setSearchedProducts([]);
        } else {
            const filteredProducts = productList.filter((product) =>
                product.productName.toLowerCase().includes(query)
            );
            setSearchedProducts(filteredProducts);
        }
        setShowDropdown(true);
    };

    const handleProductSelect = (product) => {
        setFormData({
            ...formData,
            selectedProduct: product._id,
            productName: product.productName,
        });
        setSearchedProducts([]);
        setShowDropdown(false);
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        if (value < 0) {
            alert('You cannot enter a negative value');
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            quantity: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.selectedProduct || !formData.quantity) {
            alert('Please select a product and enter a quantity');
            return;
        }

        try {
            await axios.put(`https://jubilant-backend.netlysystems.com/addQuantity/${formData.selectedProduct}`, { quantity: formData.quantity }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            alert('Quantity added successfully');
            window.location.reload();
            setFormData({
                selectedBrand: "",
                selectedProduct: "",
                productName: "",
                quantity: "",
            });
        } catch (error) {
            console.error('Error adding quantity:', error);
            alert('Failed to add quantity');
        }
    };

    return (
        <div>
            <form
                style={{
                    margin: '0 auto',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: "white",
                    maxWidth: '800px'
                }}
                onSubmit={handleSubmit}
            >
                <h1
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        color: '#333'
                    }}
                >
                    Add Quantity
                </h1>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Brand:
                    <select
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        value={formData.selectedBrand}
                        onChange={handleBrandChange}
                    >
                        <option value="">Select Brand</option>
                        {brandList.map(brand => (
                            <option key={brand._id} value={brand._id}>{brand.brand}</option>
                        ))}
                    </select>
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Product Name:
                    <div ref={dropdownRef} style={{ position: 'relative' }}>
                        <input
                            style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                            type="text"
                            placeholder="Search Product"
                            value={formData.productName}
                            onChange={handleProductSearch}
                            onClick={() => setShowDropdown(true)}
                        />
                        {showDropdown && searchedProducts.length > 0 && (
                            <ul style={{ border: "1px solid #ccc", listStyleType: "none", padding: "0", marginTop: "0", maxHeight: '150px', overflowY: 'auto', position: 'absolute', zIndex: 1, backgroundColor: 'white', width: '100%' }}>
                                {searchedProducts.map(product => (
                                    <li
                                        key={product._id}
                                        onClick={() => handleProductSelect(product)}
                                        style={{ padding: '8px', cursor: 'pointer' }}
                                    >
                                        {product.productName}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </label>
                <label style={{ display: 'block', marginBottom: '15px' }}>
                    Quantity:
                    <input
                        style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '3px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                        type="number"
                        value={formData.quantity}
                        onChange={handleQuantityChange}
                    />
                </label>
                <button
                    type="submit"
                    style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        padding: '10px 20px',
                        fontSize: '15px',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        marginTop: '20px'
                    }}
                >
                    Add Quantity
                </button>
            </form>
        </div>
    );
};

export default AddQuantity;