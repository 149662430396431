import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddMachineForm from './AddMachineForm';
import UpdateMachine from './UpdateMachine';
import './css/DashboardHome.css';

const MachineDashboard = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [clients, setClients] = useState([]);
    const [ownedby, setOwnedBy] = useState([]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };
    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const usersPerPage = 30; // Show only 30 users per page

    const fetchData = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllMachine", {
            method: "GET",
        }).then((res) => res.json())
            .then((data) => {
                setData(data.data);
            });
    }, []);

    const fetchClients = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllClientsMatch", {
            method: "GET",
        }).then((res) => res.json())
            .then((data) => {
                setClients(data.data);
            });
    }, []);

    const fetchOwnedby = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllOwnedByMatch", {
            method: "GET",
        }).then((res) => res.json())
            .then((data) => {
                setOwnedBy(data.data);
            });
    }, []);

    useEffect(() => {
        fetchData();
        fetchClients();
        fetchOwnedby();
    }, [fetchData, fetchClients, fetchOwnedby]);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    // Function to set the user to be edited
    const handleEditClient = (user) => {
        setEditMachine(user);
    };

    // State to track the user to be edited
    const [editMachine, setEditMachine] = useState(null);

    // State to manage the visibility of the Add User form
    const [showAddUserForm, setShowAddUserForm] = useState(false);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    // Function to update the user data
    const updateMachine = (userId, updatedMachineData) => {
        fetch("https://jubilant-backend.netlysystems.com/updateMachine", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                userId: userId,
                updatedMachineData: updatedMachineData,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.message === "Machine updated successfully") {
                    alert("Machine updated successfully");
                    setEditMachine(null);
                    fetchData();
                } else {
                    alert("Failed to update machine. Please try again.");
                }
            })
            .catch((error) => {
                console.error("Error updating machine:", error);
                alert("Error updating machine. Please try again.");
            });
    };

    let filteredData = data;
    if (searchTerm.trim() !== '') {
        filteredData = data.filter(user => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                user.brand?.toLowerCase().includes(term) ||
                user.type?.toLowerCase().includes(term) ||
                user.category?.toLowerCase().includes(term) ||
                user.clientName?.toLowerCase().includes(term) ||
                user.sno?.toLowerCase().includes(term) ||
                user.state?.toLowerCase().includes(term) ||
                user.battery?.toLowerCase().includes(term) ||
                user.challanNo?.toLowerCase().includes(term) ||
                user.ewaybill?.toLowerCase().includes(term) ||
                user.purchaseInvoice?.toLowerCase().includes(term) ||
                (user.loanNo && user.loanNo?.toString().toLowerCase().includes(term)) ||
                user.ownedby?.toLowerCase().includes(term) ||
                user.supervisor?.toLowerCase().includes(term) ||
                user.yearofpurchase.toString().startsWith(term) ||
                (user.weight && String(user.weight).toLowerCase().includes(term))
            );
        });
    }

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    let currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredData.length / usersPerPage);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to="/clientdashboard"><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li>
                        <a href="/machinedashboard" className="active">
                            <i className="fas fa-server icon"></i> Machines
                        </a>
                    </li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>

                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/machinedashboard">Machines</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machines :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Machine brand, type, category, site, battery-type, capacity, state, year, sno, owned by, invoice, e-way, challan No., loan.no, or supervisor..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button style={{ marginBottom: '10px', borderRadius: '10px' }} className="add-user-btn" onClick={() => setShowAddUserForm(true)}>
                            <i className="fas fa-plus-circle"></i> Add Machine
                        </button>
                        {showAddUserForm && <AddMachineForm />}
                        {editMachine && <UpdateMachine machine={editMachine} updateMachine={updateMachine} />}
                        {currentUsers.length === 0 && <p>Machine doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Brand Name</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Machine Category</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Type</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Battery Type</th>
                                    <th style={{ width: "220px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity <span style={{ color: "black", fontWeight: "bolder" }}>(TON)*</span></th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Supervisor Name</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Serial No.</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Challan No.</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>E-Way Bill</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Year of Purchase</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Owned By</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Purchase Invoice</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Purchase Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Purchase Date</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Loan Number</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Loan Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Total No. of Emi</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi Start</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi End</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.brand}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.type}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.category}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.battery}</td>
                                        <td style={{ width: "220px", border: "1px solid black" }}>{user.weight}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.clientName}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.supervisor}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.sno}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.challanNo}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.ewaybill}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.yearofpurchase}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.ownedby}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.purchaseInvoice}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.purchaseAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.purchaseDate)}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.loanNo}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.loanAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.totalEmi}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.emiAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.emiStart)}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.emiEnd)}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}><i onClick={() => handleEditClient(user)} className="fas fa-pencil-square"></i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default MachineDashboard