import React, { useEffect, useState } from 'react';
import './css/MachineForm.css';

const UpdateMachine = ({ machine, updateMachine }) => {
  const [clientMasterId, setClientMasterId] = useState(machine.clientMasterId || "");
  const [brandMasters, setBrandMasters] = useState([]);
  const [machineBrandMasterId, setBrandMasterId] = useState(machine.machineBrandMasterId || "");
  const [machineWeightMasterId, setWeightMasterId] = useState(machine.machineWeightMasterId || "");
  const [typeMasters, setTypeMasters] = useState([]);
  const [stateMasters, setStateMasters] = useState([]);
  const [ownedbyMasters, setOwnedbyMasters] = useState([]);
  const [machineTypeMasterId, setTypeMasterId] = useState(machine.machineTypeMasterId || "");
  const [stateMasterId, setStateMasterId] = useState(machine.stateMasterId || "");
  const [ownedbyMasterId, setOwnedbyMasterId] = useState(machine.ownedbyMasterId || "");
  const [categoryMasters, setCategoryMasters] = useState([]);
  const [machineCategoryMasterId, setCategoryMasterId] = useState(machine.machineCategoryMasterId || "");
  const [batteryMasterId, setBatteryMasterId] = useState(machine.batteryMasterId || "");
  const [brand, setBrand] = useState(machine.brand || "");
  const [weight, setWeight] = useState(machine.weight || "");
  const [type, setType] = useState(machine.type || "");
  const [state, setState] = useState(machine.state || "");
  const [ownedby, setOwnedby] = useState(machine.ownedby || "");
  const [category, setCategory] = useState(machine.category || "");
  const [battery, setBattery] = useState(machine.battery || "");
  const [supervisor, setSupervisor] = useState(machine.supervisor || "");
  const [sno, setSno] = useState(machine.sno || "");
  const [challanNo, setChallanNo] = useState(machine.challanNo || "");
  const [ewaybill, setEwayBill] = useState(machine.ewaybill || "");
  const [yearofpurchase, setYearofPurchase] = useState(machine.yearofpurchase || "");
  const [purchaseInvoice, setPurchaseInvoice] = useState(machine.purchaseInvoice || "");
  const [purchaseAmount, setPurchaseAmount] = useState(machine.purchaseAmount || "");
  const [purchaseDate, setPurchaseDate] = useState(machine.purchaseDate || "");
  const [loanNo, setLoanNo] = useState(machine.loanNo || "");
  const [loanAmount, setLoanAmount] = useState(machine.loanAmount || 0);
  const [totalEmi, setTotalEmi] = useState(machine.totalEmi || "");
  const [emiAmount, setEmiAmount] = useState(machine.emiAmount || 0);
  const [emiStart, setEmiStart] = useState(machine.emiStart || "");
  const [emiEnd, setEmiEnd] = useState(machine.emiEnd || "");
  const [clientsList, setClientsList] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [ownedbys, setOwnedBys] = useState([]);
  const [machineCategory, setMachineCategory] = useState([]);
  const [machineBattery, setMachineBattery] = useState([]);
  const [machineBrand, setMachineBrand] = useState([]);
  const [machineWeight, setMachineWeight] = useState([]);
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [error, setError] = useState(null);
  const [isBatteryFieldDisabled, setIsBatteryFieldDisabled] = useState(true);

  useEffect(() => {
    fetchClients();
    fetchSupervisors();
    fetchMachineTypes();
    fetchStates();
    fetchMachineCategory();
    fetchMachineBrand();
    fetchMachineWeight();
    fetchCompany();
    fetchBatteries();
  }, []);

  useEffect(() => {
    if (machineCategoryMasterId) {
      const selectedCategory = machineCategory.find(c => c._id === machineCategoryMasterId);
      setIsBatteryFieldDisabled(!(selectedCategory && selectedCategory.category.toLowerCase() === 'battery'));
    }
  }, [machineCategoryMasterId, machineCategory]);

  const fetchStates = () => {
    fetch("https://jubilant-backend.netlysystems.com/states")
      .then((res) => res.json())
      .then((data) => {
        setStates(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchCompany = () => {
    fetch("https://jubilant-backend.netlysystems.com/companyNames")
      .then((res) => res.json())
      .then((data) => {
        setOwnedBys(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchBatteries = () => {
    fetch("https://jubilant-backend.netlysystems.com/batteries")
      .then((res) => res.json())
      .then((data) => {
        setMachineBattery(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineBrand = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineBrand")
      .then((res) => res.json())
      .then((data) => {
        setMachineBrand(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineWeight = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineWeight")
      .then((res) => res.json())
      .then((data) => {
        setMachineWeight(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineCategory = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineCategory")
      .then((res) => res.json())
      .then((data) => {
        setMachineCategory(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMachineTypes = () => {
    fetch("https://jubilant-backend.netlysystems.com/machineTypes")
      .then((res) => res.json())
      .then((data) => {
        setMachineTypes(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchClients = () => {
    fetch("https://jubilant-backend.netlysystems.com/fetchclients")
      .then((res) => res.json())
      .then((data) => {
        setClientsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSupervisors = () => {
    fetch("https://jubilant-backend.netlysystems.com/supervisors")
      .then((res) => res.json())
      .then((data) => {
        setSupervisorsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const client = clientsList.find(client => client.clientMasterId === clientMasterId);
    const clientName = client ? client.fname : 'Unknown';
    const updatedMachineData = {
      machineWeightMasterId,
      weight,
      machineBrandMasterId,
      brand,
      machineTypeMasterId,
      type,
      stateMasterId,
      state,
      machineCategoryMasterId,
      category,
      clientMasterId,
      clientName,
      supervisor,
      sno,
      yearofpurchase,
      ownedbyMasterId,
      ownedby,
      purchaseInvoice,
      purchaseAmount,
      purchaseDate,
      loanNo,
      loanAmount,
      totalEmi,
      emiAmount,
      emiStart,
      emiEnd,
      challanNo,
      ewaybill,
      batteryMasterId: !isBatteryFieldDisabled ? batteryMasterId : undefined,
      battery: !isBatteryFieldDisabled ? machineBattery.find(b => b._id === batteryMasterId)?.battery : undefined,
    };
    updateMachine(machine._id, updatedMachineData);
  };

  return (
    <form style={{ margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }} onSubmit={handleSubmit} className="machine-form">
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Update Machine</h1>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Machine Brand</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={machineBrandMasterId}
          onChange={(e) => {
            setBrandMasterId(e.target.value);
            const selectedBrand = machineBrand.find(b => b._id === e.target.value);
            setBrand(selectedBrand ? selectedBrand.brand : '');
          }} required
        >
          <option value="">Select Machine Brand</option>
          {machineBrand.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.brand}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Machine Capacity <span style={{ color: "red", fontWeight: "bolder" }} >(TON)*</span></label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={machineWeightMasterId}
          onChange={(e) => {
            setWeightMasterId(e.target.value);
            const selectedWeight = machineWeight.find(b => b._id === e.target.value);
            setWeight(selectedWeight ? selectedWeight.weight : '');
          }} required
        >
          <option value="">Select Machine Capacity</option>
          {machineWeight.map((weight) => (
            <option key={weight._id} value={weight._id}>
              {weight.weight}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Category of Machine</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={machineTypeMasterId}
          onChange={(e) => {
            setTypeMasterId(e.target.value);
            const selectedType = machineTypes.find(t => t._id === e.target.value);
            setType(selectedType ? selectedType.type : '');
          }} required
        >
          <option value="">Select Machine Category</option>
          {machineTypes.map((type) => (
            <option key={type._id} value={type._id}>
              {type.type}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Machine Type</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={machineCategoryMasterId}
          onChange={(e) => {
            setCategoryMasterId(e.target.value);
            const selectedCategory = machineCategory.find(c => c._id === e.target.value);
            setCategory(selectedCategory ? selectedCategory.category : '');
          }} required
        >
          <option value="">Select Machine Type</option>
          {machineCategory.map((category) => (
            <option key={category._id} value={category._id}>
              {category.category}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Battery Type</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={batteryMasterId}
          onChange={(e) => {
            setBatteryMasterId(e.target.value);
            const selectedBattery = machineBattery.find(b => b._id === e.target.value);
            setBattery(selectedBattery ? selectedBattery.battery : '');
          }}
          disabled={isBatteryFieldDisabled} required
        >
          <option value="">Select Battery Type</option>
          {machineBattery.map((battery) => (
            <option key={battery._id} value={battery._id}>
              {battery.battery}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Site</label>
        <select
          className="form-control"
          style={{ width: '100%' }}
          value={clientMasterId}
          onChange={(e) => {
            setClientMasterId(e.target.value);
          }}
          required
        >
          <option value="">Select Site</option>
          {clientsList.map((client) => (
            <option key={client._id} value={client.clientMasterId}>
              {client.fname}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>States</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={stateMasterId}
          onChange={(e) => {
            setStateMasterId(e.target.value);
            const selectedState = states.find(t => t._id === e.target.value);
            setState(selectedState ? selectedState.state : '');
          }} required
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state._id} value={state._id}>
              {state.state}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Supervisor</label>
        <select
          className="form-control"
          style={{ width: '100%' }}
          value={supervisor}
          onChange={(e) => setSupervisor(e.target.value)} required
        >
          <option value="">Select Supervisor</option>
          {supervisorsList.map((supervisor) => (
            <option key={supervisor._id} value={supervisor.fname}>
              {supervisor.fname}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Serial.No</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Serial No"
          style={{ width: '100%' }}
          value={sno}
          onChange={(e) => setSno(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Challan No.</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Challan No"
          style={{ width: '100%' }}
          value={challanNo}
          onChange={(e) => setChallanNo(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>E-Way Bill</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter E-Way Bill"
          style={{ width: '100%' }}
          value={ewaybill}
          onChange={(e) => setEwayBill(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Year Of Purchase:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Year Of Purchase"
          style={{ width: '100%' }}
          value={yearofpurchase}
          onChange={(e) => setYearofPurchase(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Owned By</label>
        <select
          className="form-select"
          style={{ width: '100%' }}
          value={ownedbyMasterId}
          onChange={(e) => {
            setOwnedbyMasterId(e.target.value);
            const selectedownedby = ownedbys.find(t => t._id === e.target.value);
            setOwnedby(selectedownedby ? selectedownedby.ownedby : '');
          }} required
        >
          <option value="">Select Owned By</option>
          {ownedbys.map((ownedby) => (
            <option key={ownedby._id} value={ownedby._id}>
              {ownedby.ownedby}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Invoice:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Purchase Invoice"
          style={{ width: '100%' }}
          value={purchaseInvoice}
          onChange={(e) => setPurchaseInvoice(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Purchase Amount"
          style={{ width: '100%' }}
          value={purchaseAmount}
          onChange={(e) => setPurchaseAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Purchase Date:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          value={purchaseDate}
          onChange={(e) => setPurchaseDate(e.target.value)}
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Loan.No:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Loan No"
          style={{ width: '100%' }}
          value={loanNo}
          onChange={(e) => setLoanNo(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Loan Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Loan Amount"
          style={{ width: '100%' }}
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Total No. of Emi:</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Total Emi"
          style={{ width: '100%' }}
          value={totalEmi}
          onChange={(e) => setTotalEmi(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi Amount:</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter Emi Amount"
          style={{ width: '100%' }}
          value={emiAmount}
          onChange={(e) => setEmiAmount(e.target.value)} required
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi Start:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          value={emiStart}
          onChange={(e) => setEmiStart(e.target.value)}
        />
      </div>

      <div style={{ marginBottom: '1rem', width: '100%' }}>
        <label>Emi End:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: '100%' }}
          value={emiEnd}
          onChange={(e) => setEmiEnd(e.target.value)}
        />
      </div>

      <div style={{ width: '100%' }}>
        <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
          Update Machine
        </button>
      </div>
    </form>
  );
}

export default UpdateMachine;