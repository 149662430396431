import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const SupervisorRequestForm = () => {
    const [requestedQuantity, setRequestedQuantity] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [productList, setProductList] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [supervisorName, setSupervisorName] = useState('');
    const [machines, setMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedOwnedBy, setSelectedOwnedBy] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [isProductNameSelected, setIsProductNameSelected] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchProductList = async () => {
            try {
                const response = await axios.get('https://jubilant-backend.netlysystems.com/inventory/supervisor-productNames', {
                    params: { brand: selectedBrand }
                });
                setProductList(response.data);
            } catch (error) {
                console.error('Error fetching product list:', error);
            }
        };

        const fetchMachines = async () => {
            try {
                const token = window.localStorage.getItem('token');
                const response = await axios.get('https://jubilant-backend.netlysystems.com/supervisor-clients-machines', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMachines(response.data.machines);
            } catch (error) {
                console.error('Error fetching machines:', error);
            }
        };

        const token = window.localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        setSupervisorEmail(decodedToken.email);
        setSupervisorName(decodedToken.name);

        fetchProductList();
        fetchMachines();
    }, [selectedBrand]);

    useEffect(() => {
        if (searchTerm === '') {
            setSearchedProducts([]);
        } else {
            setSearchedProducts(productList.filter(product =>
                product.productName.toLowerCase().includes(searchTerm.toLowerCase()) &&
                product.brand === selectedBrand
            ));
        }
    }, [searchTerm, productList, selectedBrand]);

    const handleProductChange = (product) => {
        setSelectedProduct(product.productName);
        setSearchTerm(product.productName); // Update searchTerm to reflect the selected product
        setSearchedProducts([]);
        setShowDropdown(false); // Hide dropdown after selection
        setIsProductNameSelected(true);
    };

    const handleProductSearch = (e) => {
        setSearchTerm(e.target.value);
        setShowDropdown(true); // Show dropdown while typing
    };

    const handleMachineChange = (e) => {
        const selectedSno = e.target.value;
        setSelectedMachine(selectedSno);

        const selectedMachine = machines.find((machine) => machine.sno === selectedSno);
        if (selectedMachine) {
            setSelectedBrand(selectedMachine.brand);
            setSelectedClient(selectedMachine.clientName);
            setSelectedOwnedBy(selectedMachine.ownedby);
            setSelectedProduct('');
            const filtered = productList.filter(product => product.brand === selectedMachine.brand);
            setSearchedProducts(filtered);
        }
    };

    const handleBrandChange = async (e) => {
        const newBrand = e.target.value;
        setSelectedBrand(newBrand);
        setSelectedProduct('');

        // Fetch products for the selected brand
        try {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/inventory/supervisor-productNames', {
                params: { brand: newBrand }
            });
            setProductList(response.data);
        } catch (error) {
            console.error('Error fetching filtered products:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isProductNameSelected) {
            alert('Please select a product name from the dropdown.');
            return;
        }

        const selectedProductData = productList.find(product => product.productName === selectedProduct && product.brand === selectedBrand);

        if (selectedProductData) {
            try {
                await axios.post('https://jubilant-backend.netlysystems.com/requests', {
                    productNameMasterId: selectedProductData._id,
                    productName: selectedProductData.productName,
                    requestedQuantity,
                    supervisorEmail,
                    supervisorName,
                    sno: selectedMachine,
                    brand: selectedBrand,
                    clientName: selectedClient,
                    ownedby: selectedOwnedBy
                });
                alert("Request submitted successfully");
                window.location.href = "./supervisorRequest";
            } catch (error) {
                console.error('Error submitting request:', error);
            }
        } else {
            console.error('Selected product not found');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: 'white', marginBottom: "20px" }}>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Machine Serial Number:
                <select
                    className="form-select"
                    style={{ width: '100%' }}
                    value={selectedMachine}
                    onChange={handleMachineChange}
                    required
                >
                    <option value="">Select Machine Serial Number</option>
                    {machines.map((machine, index) => (
                        <option key={index} value={machine.sno}>
                            {machine.sno}
                        </option>
                    ))}
                </select>
            </label>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Selected Machine Brand:
                <select
                    className="form-select"
                    style={{ width: '100%' }}
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    required
                    disabled
                >
                    <option value="">Selected Machine Brand</option>
                    {machines.map((machine, index) => (
                        <option key={index} value={machine.brand}>
                            {machine.brand}
                        </option>
                    ))}
                </select>
            </label>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Selected Client Name:
                <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    value={selectedClient}
                    readOnly
                />
            </label>
            <label style={{ display: 'block', marginBottom: '15px' }}>
                Product Name:
                <div style={{ position: 'relative' }}>
                    <input
                        className="form-control"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Enter Product Name"
                        value={searchTerm}
                        onChange={handleProductSearch}
                        onClick={() => setShowDropdown(true)}
                        required
                    />
                    {showDropdown && (
                        <ul ref={dropdownRef} style={{ listStyleType: 'none', padding: 0, position: 'absolute', zIndex: 1, backgroundColor: '#fff', width: '100%', maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '5px', marginTop: '5px' }}>
                            {searchedProducts.length > 0 ? searchedProducts.map((product, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleProductChange(product)}
                                    style={{ cursor: 'pointer', padding: '8px', borderBottom: '1px solid #ccc' }}
                                >
                                    {product.productName}
                                </li>
                            )) : productList.map((product, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleProductChange(product)}
                                    style={{ cursor: 'pointer', padding: '8px', borderBottom: '1px solid #ccc' }}
                                >
                                    {product.productName}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </label>
            <label htmlFor="requestedQuantity" style={{ display: 'block', marginBottom: '15px' }}>
                Requested Quantity:
                <input
                    id="requestedQuantity"
                    className="form-control"
                    style={{ width: '100%' }}
                    type="number"
                    value={requestedQuantity}
                    onChange={(e) => setRequestedQuantity(e.target.value)}
                    required
                />
            </label>
            <button type="submit" className="btn btn-primary" style={{ width: '100%', marginTop: '15px' }}>
                Submit Request
            </button>
        </form>
    );
};

export default SupervisorRequestForm;