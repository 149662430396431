import React, { useEffect, useState } from 'react'

const UpdateClient = ({ client, updateClient }) => {
  const [fname, setFname] = useState(client.fname);
  const [email, setEmail] = useState(client.email);
  const [address, setAddress] = useState(client.address);
  const [gstno, setGstno] = useState(client.gstno);
  const [phone, setPhone] = useState(client.phone);
  const [supervisor, setSupervisor] = useState(client.supervisor);
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [clientNames, setClientNames] = useState([]);

  useEffect(() => {
    fetchClientNames();
    fetchSupervisors();
  }, []);

  const fetchClientNames = () => {
    fetch("https://jubilant-backend.netlysystems.com/clientNames")
      .then((res) => res.json())
      .then((data) => {
        setClientNames(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchSupervisors = () => {
    fetch("https://jubilant-backend.netlysystems.com/supervisors")
      .then((res) => res.json())
      .then((data) => {
        setSupervisorsList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateClient(client._id, { fname, email, address, gstno, phone, supervisor });
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white" }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Site</h2>

      <label style={{ display: 'block', marginBottom: '5px' }}>Site Name:</label>
      <select
        className="form-select"
        value={fname}
        style={{ width: '100%' }}
        onChange={(e) => setFname(e.target.value)} required
      >
        <option value="">Select Site</option>
        {clientNames.map((client) => (
          <option key={client._id} value={client.fname}>
            {client.fname}
          </option>
        ))}
      </select>

      <label style={{ display: 'block', marginBottom: '5px' }}>Email:</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '3px', border: '1px solid #ccc' }} />

      <label style={{ display: 'block', marginBottom: '5px' }}>Address:</label>
      <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '3px', border: '1px solid #ccc' }} />

      <label style={{ display: 'block', marginBottom: '5px' }}>Gst.No:</label>
      <input type="text" value={gstno} onChange={(e) => setGstno(e.target.value)} required style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '3px', border: '1px solid #ccc' }} />

      <label style={{ display: 'block', marginBottom: '5px' }}>Phone:</label>
      <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} required style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '3px', border: '1px solid #ccc' }} />
      
      <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Update Client</button>
    </form>
  )
}

export default UpdateClient