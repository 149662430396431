import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const SupervisorInventoryReport = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(true);
    const [supervisors, setSupervisors] = useState([]);
    const [clients, setClients] = useState([]);
    const [machineSnos, setMachineSnos] = useState([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedMachineSno, setSelectedMachineSno] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        axios.get('https://jubilant-backend.netlysystems.com/supervisors-inventory-reportName')
            .then(res => setSupervisors(res.data))
            .catch(err => console.error(err));
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/sign-in";
        return null;
    }

    const handleSupervisorChange = async (e) => {
        const supervisor = e.target.value;
        setSelectedSupervisor(supervisor);
        setSelectedClient('');
        setSelectedMachineSno('');
        setClients([]);
        setMachineSnos([]);

        if (supervisor) {
            const response = await axios.get(`https://jubilant-backend.netlysystems.com/clients-for-supervisor-inventory/${supervisor}`);
            setClients(response.data);
        } else {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/clients-for-supervisor-inventory/all');
            setClients(response.data);
        }

        if (supervisor) {
            const machineResponse = await axios.get(`https://jubilant-backend.netlysystems.com/machine-snos-for-client-inventory/all/${supervisor}`);
            setMachineSnos(machineResponse.data);
        }
    };

    const handleClientChange = async (e) => {
        const client = e.target.value;
        setSelectedClient(client);
        setSelectedMachineSno('');
        setMachineSnos([]);

        if (client) {
            const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-snos-for-client-inventory/${client}/${selectedSupervisor}`);
            setMachineSnos(response.data);
        } else if (selectedSupervisor) {
            const response = await axios.get(`https://jubilant-backend.netlysystems.com/machine-snos-for-client-inventory/all/${selectedSupervisor}`);
            setMachineSnos(response.data);
        } else {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/machine-snos-for-client-inventory/all');
            setMachineSnos(response.data);
        }
    };

    const generateReport = async (format) => {
        try {
            const response = await axios.post('https://jubilant-backend.netlysystems.com/generate-supervisor-inventory-report', {
                supervisorName: selectedSupervisor,
                clientName: selectedClient,
                sno: selectedMachineSno,
                startDate,
                endDate,
            }, {
                responseType: 'blob',
                params: { format } // Pass format as a query parameter
            });

            const fileName = format === 'pdf'
                ? `supervisor_inventory_report_${selectedSupervisor}.pdf`
                : `supervisor_inventory_report_${selectedSupervisor}.xlsx`;

            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error generating report', error);
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>

                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li>
                                <a href="/supervisor-inventory-report" className="active">
                                    <i className="fas fa-archive icon"></i>Supervisor Inventory Report
                                </a>
                            </li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisor-inventory-report">Supervisor Inventory Report</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Supervisor Inventory Report :</h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor: "#F1F0F6" }}>
                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="supervisor">Supervisor</label>
                            <select
                                id="supervisor"
                                value={selectedSupervisor}
                                onChange={handleSupervisorChange}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                            >
                                <option value="">Select Supervisor</option>
                                <option value="all">All Supervisor</option>
                                {supervisors.map((supervisor) => (
                                    <option key={supervisor} value={supervisor}>{supervisor}</option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="Site">Site</label>
                            <select
                                id="client"
                                value={selectedClient}
                                onChange={handleClientChange}
                                disabled={!selectedSupervisor}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                            >
                                <option value="">Select Site</option>
                                <option value="all">All Site</option>
                                {clients.map((client) => (
                                    <option key={client} value={client}>{client}</option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="machineSno">Machine SNO</label>
                            <select
                                id="machineSno"
                                value={selectedMachineSno}
                                onChange={(e) => setSelectedMachineSno(e.target.value)}
                                disabled={!selectedClient}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                            >
                                <option value="">Select Machine</option>
                                <option value="all">All Machines</option>
                                {machineSnos.map((sno) => (
                                    <option key={sno} value={sno}>{sno}</option>
                                ))}
                            </select>

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="startDate">Start Date</label>
                            <input
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                            />

                            <label style={{ marginBottom: '10px', fontSize: '1.2em', fontWeight: 'bold' }} htmlFor="endDate">End Date</label>
                            <input
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px', borderRadius: '3px', border: '1px solid #ccc', outline: 'none' }}
                            />
                            <button type="button" onClick={generateReport}>Generate Report</button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default SupervisorInventoryReport;