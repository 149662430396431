import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./css/DashboardHome.css";
import { jwtDecode } from "jwt-decode";

const SupervisorInventory = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const usersPerPage = 30; // Show users per page

    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const supervisorEmail = decodedToken.email;

                const response = await axios.get(`https://jubilant-backend.netlysystems.com/inventory-requests?supervisorEmail=${supervisorEmail}`);
                const sortedRequests = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setRequests(sortedRequests);
                setData(sortedRequests);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching requests:', error);
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleInputChange = (e, requestId) => {
        const value = parseInt(e.target.value);
        const updatedRequests = requests.map(request =>
            request._id === requestId ? { ...request, useQuantity: value } : request
        );
        setRequests(updatedRequests);
    };

    const handleUseItem = async (requestId, useQuantity) => {
        try {
            await axios.put(`https://jubilant-backend.netlysystems.com/inventory/${requestId}/use`, { usedQuantity: useQuantity });

            const updatedRequests = requests.map(request =>
                request._id === requestId ? { ...request, usedQuantity: request.usedQuantity + useQuantity, remainingQuantity: request.remainingQuantity - useQuantity, useQuantity: '' } : request
            );
            setRequests(updatedRequests);
            alert("Quantity Updated Successfully!")
        } catch (error) {
            console.error('Error using item:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    let filteredData = requests;
    if (searchTerm.trim() !== '') {
        filteredData = data.filter(request => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                request.productName?.toLowerCase().includes(term) ||
                request.sno?.toLowerCase().includes(term) ||
                request.brand?.toLowerCase().includes(term)
            );
        });
    }

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    const indexOfLastUser = Math.min(currentPage * usersPerPage);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to="/supervisorMachine"><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to="/supervisorRequest"><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to="/supervisorInspection"><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to="/supervisorDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li>
                        <Link to="/supervisorInventory" className="active">
                            <i className="fas fa-store icon"></i> Supervisor Inventory
                        </Link>
                    </li>
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title">Hello Supervisor,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisorInventory">Supervisor Inventory</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <h2>Supervisor Store</h2>
                    <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                        <h1 style={{ color: "white", margin: 2 }}>Inventory :</h1>
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search by Machine S.no, Brand or Product Name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {filteredData.length === 0 && <p>Inventory Item doesn't exist</p>}
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                <th style={{ width: "125px", backgroundColor: "#007bff", color: "white" }}>Date</th>
                                <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine S.No</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Brand</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Total Stock</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Remaining Stock</th>
                                <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Used Stock</th>
                                <th style={{ width: "260px", backgroundColor: "#007bff", color: "white" }}>Use Stock</th>
                                <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Use Item</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((request, index) => (
                                <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={request._id}>
                                    <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstUser + index + 1}</td>
                                    <td style={{ width: "125px", border: "1px solid black" }}>{formatDate(request.updatedAt)}</td>
                                    <td style={{ width: "350px", border: "1px solid black" }}>{request.productName}</td>
                                    <td style={{ width: "350px", border: "1px solid black" }}>{request.clientName}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{request.sno}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{request.brand}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{request.quantity}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{request.remainingQuantity}</td>
                                    <td style={{ width: "180px", border: "1px solid black" }}>{request.usedQuantity}</td>
                                    <td style={{ width: "260px", border: "1px solid black" }}>
                                        <input
                                            type="number"
                                            min="0"
                                            max={request.remainingQuantity}
                                            value={request.useQuantity || ""}
                                            onChange={(e) => handleInputChange(e, request._id)}
                                        />
                                        {request.useQuantity > request.remainingQuantity && (
                                            <span style={{ color: 'red' }}>Total Stock exceeded!!</span>
                                        )}
                                    </td>
                                    <td style={{ width: "140px", textAlign: 'center' }}>
                                        <button className="btn btn-primary" style={{ margin: 'auto', display: 'block' }} onClick={() => handleUseItem(request._id, request.useQuantity || 0)} disabled={!request.useQuantity || request.useQuantity > request.remainingQuantity}>
                                            Use
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)} disabled={currentPage === 1}>
                            Previous Page
                        </button>
                        <button onClick={() => paginate(currentPage + 1)} disabled={indexOfLastUser >= requests.length}>
                            Next Page
                        </button>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default SupervisorInventory;