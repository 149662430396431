import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import axios from 'axios';

const SuperAdminRequest = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [requests, setRequests] = useState([]);

    const usersPerPage = 30; // Show users per page

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchRequests = async () => {
        try {
            const response = await axios.get('https://jubilant-backend.netlysystems.com/requests');
            setRequests(response.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
    }, []);

    useEffect(() => {
        fetchRequests();
        const intervalId = setInterval(fetchRequests, 100);
        return () => clearInterval(intervalId);
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const filteredRequests = requests.filter((request) =>
        (request.supervisorName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.brand?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.sno?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.clientName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.status?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (request.productName?.toLowerCase() ?? '').includes(searchTerm.toLowerCase())
    );

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    // Sort filtered requests by createdAt in descending order (newest first)
    const sortedRequests = filteredRequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Paginate the sorted requests
    const indexOfLastUser = Math.min(currentPage * usersPerPage, sortedRequests.length);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;
    const currentRequests = sortedRequests.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(sortedRequests.length / usersPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li>
                        <a href="/supervisorAllRequests" className="active">
                            <i className="fas fa-ticket icon"></i> Requests
                        </a>
                    </li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title">Hello SuperAdmin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/supervisorAllRequests">Requests</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div>
                        <h2>All Supervisors Requests</h2>
                        <div className="users-page">
                            <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                                <h1 style={{ color: "white", margin: 2 }}>Requests :</h1>
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search by Supervisors Name, Site, Product Name, Brand, S.No..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        {filteredRequests.length === 0 && <p>Supervisor doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Requested Date</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Supervisor Name</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Brand</th>
                                    <th style={{ width: "180px", backgroundColor: "#007bff", color: "white" }}>Machine Serial No.</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Requested Quantity</th>
                                    <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Sent Quantity</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>Remaining Quantity</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRequests.map((request, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={request._id}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstUser + index + 1}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{formatDate(request.createdAt)}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.clientName}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.supervisorName}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.brand}</td>
                                        <td style={{ width: "180px", border: "1px solid black" }}>{request.sno}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{request.productName}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.requestedQuantity}</td>
                                        <td style={{ width: "130px", border: "1px solid black" }}>{request.sentQuantity}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{request.remainingQuantity}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{request.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => paginate(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default SuperAdminRequest;