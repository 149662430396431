import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';

const AdminUserHome = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30;

    const fetchData = useCallback(() => {
        fetch("https://jubilant-backend.netlysystems.com/getAllUser", {
            method: "GET",
        }).then((res) => res.json())
            .then((data) => {
                setData(data.data);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    let filteredData = data;
    if (searchTerm.trim() !== '') {
        filteredData = data.filter(user => {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            return searchTerms.every(term =>
                user.fname.toLowerCase().includes(term) ||
                user.email.toLowerCase().includes(term) ||
                (user.phone && user.phone.toString().toLowerCase().includes(term)) ||
                (user.godownName && user.godownName.toLowerCase().includes(term)) ||
                user.userType.toLowerCase().includes(term) ||
                (user.city && user.city.toLowerCase().includes(term)) ||
                (user.state && user.state.toLowerCase().includes(term))
            );
        });
    }

    // Logic for pagination
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    let currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <Link to="/adminUser" className="active">
                            <i className="fas fa-user icon"></i> Users
                        </Link>
                    </li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>

                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminUser">Users</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Users :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by name, email, phone, or role..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {currentUsers.length === 0 && <p>User doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Name</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Role</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Godown</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Email</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>City</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Phone</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.fname}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.userType}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.godownName ? user.godownName : '-'}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.email}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{user.city}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.phone}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={indexOfLastUser >= data.length}
                            >
                                Next Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
};

export default AdminUserHome;