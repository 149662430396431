import React, { useEffect, useState } from "react";
import "./css/DashboardHome.css";
import image from './css/forklift.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
export default function StorekeeperHome({ userData }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
  
  const [counts, setCounts] = useState({
    machines: 0
  })

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const machinesResponse = await axios.get('https://jubilant-backend.netlysystems.com/machines/count');

        setCounts({
          machines: machinesResponse.data.count
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/"
  };

  if (!isLoggedIn) {
    alert("Token Expired Login!! To see Content")
    window.location.href = "/";
    return null;
  }

  return (
    <>
      {/* SIDEBAR */}
      <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
        <Link to="/" className="brand">
          <i className="fas fa-smile icon"></i>CRM
        </Link>
        <ul className="side-menu">
          <li>
            <Link to="/" className="active">
              <i className="fas fa-dashboard icon"></i> Dashboard
            </Link>
          </li>
          <li className="divider" data-text="main">
            Main
          </li>
          <li><Link to="/storekeeperRequest"><i className="fas fa-ticket icon"></i>Requests</Link></li>
          <li><Link to="/storekeeperInventory"><i className="fas fa-store icon"></i>Inventory</Link></li>
          <li><Link to="/storekeeperDeadStock"><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
        </ul>
        <div className="ads">
          <div className="wrapper">
            <Link to="#" className="btn-upgrade" onClick={logOut}>
              Logout
            </Link>
            <p>
              See you <span>Later!</span> {userData.fname} Enjoy & <span>Have Fun!</span>
            </p>
          </div>
        </div>
      </section>
      {/* SIDEBAR */}

      {/* NAVBAR */}
      <section id="content">
        <nav>
          <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
        </nav>

        <main>
          <h4 className="title">Hello Storekeeper {userData.fname},</h4>
          <ul className="breadcrumbs">
            <li><Link to="/">Home</Link></li>
            <li className="divider">/</li>
            <li><Link to="#" className="active">Dashboard</Link></li>
          </ul>
          <ul className="box-info">
            <li>
              <i className="fas fa-server"></i>
              <span className="text">
                <p>Total Machines:</p>
                <h3>{counts.machines}</h3>
              </span>
            </li>
          </ul>
          <div className="image-container">
            <img className="forklift" src={image} alt="Forklift" />
          </div>
        </main>
      </section>
      {/* NAVBAR */}
    </>
  );
}